import React, {useEffect, useState} from "react"
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Segment, Button, Icon, Input } from 'semantic-ui-react';
import { APIBASEURL, CUSTOMSTYLES } from "../../config";

const OrderList = () => {
    const [orders, getOrders] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    useEffect(()=>{
        getAllOrders();
    },[]);
    const getAllOrders = () => {
        axios.get(`${APIBASEURL}orders`).then((response) => {
            const allOrders = response.data;
            getOrders(allOrders);
            setFilteredData(allOrders);
        }).catch(error => console.log(`Error: ${error}`))
    }
    const handleFilter = (e) => {
      const searchValue = e.target.value;
      const filteredData = orders.filter((item) =>
        item.user.name.toLowerCase().includes(searchValue.toLowerCase()) || item.id.toString().toLowerCase().includes(searchValue.toLowerCase()) || item.user.email.toLowerCase().includes(searchValue.toLowerCase()) || item.order_date.toLowerCase().includes(searchValue.toLowerCase()) || item.status.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filteredData);
    };
   
    const columns = [
      {
        name: "Order Id",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Customer Name",
        selector: (row) => row.user.name,
        sortable: true,
      },
      {
        name: "Customer Email",
        selector: (row) => row.user.email,
        sortable: true,
      },
      {
        name: "Order Date",
        selector: (row) => row.order_date,
        sortable: true,
      },
      {
        name: "Order Status",
        selector: (row) => row.status,
        sortable: true,
      },
      {
        name: "Amount",
        selector: (row) => row.grand_total,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div>
            <Button.Group icon>
              <Button as='a' href={`/admin/orders/${row.id}`}>
                <Icon name="eye" />
              </Button>
            </Button.Group>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        grow: "2",
      },
    ];
    
    return (
        <Segment>
            <Input fluid icon='search' placeholder='Search by Order ID / Customer Name / Email / Order Date' onChange={handleFilter} />
            <DataTable
                title="All Orders"
                columns={columns}
                data={filteredData}
                pagination
                responsive
                fixedHeaderScrollHeight="100px"
                customStyles={CUSTOMSTYLES}
            />
        </Segment>
    );
}

export default OrderList