import React, { useState, useEffect } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import api from "../../api";
import validator from "validator";
import { LOCAL_STORAGE_KEY_USER } from "../../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const BasicInfo = ({ localUser }) => {
  console.log("localUser", localUser);
  const [error, setError] = useState("");
  const [btnLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [user, setUser] = useState(false);

  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    const theUser = await api.get(`users/${localUser.user_id}`);
    console.log("theUser.data", theUser.data);
    if (theUser.status === 200 && theUser.data) {
      let usr = theUser.data;
      let ctr = theUser.data.customers;
      setInputs({
        user_id: usr.id,
        name: usr.name,
        company_name: ctr?.company_name,
        phone_primary: ctr?.phone_primary,
        phone_secondary: ctr?.phone_secondary,
      });
      setUser(theUser.data);
    }
  };
  const [validError, setValidError] = useState({
    name: "",
    company_name: "",
    phone_primary: "",
    phone_secondary: "",
  });
  
  const [success, setSuccess] = useState("");

  const handleChange = (event, data) => {
    if (data) {
      const name = data.name;
      const value = data.value;
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    console.log("inputs", JSON.stringify(inputs));
    let i = 0;
    Object.keys(inputs).map(async (key) => {
      console.log("key " + i + ":", key);
      switch (key) {
        case "name":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({
                ...values,
                [key]: "Full Legal Name is required",
              }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "phone_primary":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({
                ...values,
                [key]: "Invalid phone number",
              }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        default:
          break;
      }
      i++;
    });
    if (validError.name === "" && validError.phone_primary === "") {
      Object.keys(inputs).map((key) => {
        formData.append(key, inputs[key]);
      });
      formData.append('user_id', user.id);
      const response = await api.post("userauth/updatebasicinfo", formData);
      console.log("response", response.data);
      if (response.status === 200 && response.data.status === true) {
        setSuccess(response.data.msg);
        setError("");
      } else {
        setError("Invalid input, failed to create user account");
        setSuccess("");
      }
    }
    setLoading(false);
  };
  console.log("user",user);
  console.log("inputs",inputs);
  return (
    <fieldset>
      <legend>Basic Information</legend>
      {success === "" ? "" :(<Message color="green">{success}</Message>)}
      {error ==="" ?"" :(<Message color="red">{error}</Message>)}
      <label>
        Full Legal Name <span className="required">*</span>
      </label>
      <Form.Input
        placeholder="Full Legal Name"
        name="name"
        onChange={handleChange}
        value={inputs.name}
      />
      {validError.name !== "" ? (
        <Message color="red">{validError.name}</Message>
      ) : (
        ""
      )}
      <label>Company</label>
      <Form.Input
        fluid
        placeholder="Company Name"
        name="company_name"
        onChange={handleChange}
        value={inputs?.company_name}
      />
      <label>
        Primary Phone <span className="required">*</span>
      </label>
      <Form.Input
        fluid
        placeholder="Primary Phone"
        name="phone_primary"
        onChange={handleChange}
        value={inputs.phone_primary}
      />
      {validError.phone_primary != "" ? (
        <Message color="red">{validError.phone_primary}</Message>
      ) : (
        ""
      )}
      <label>Secondary Phone</label>
      <Form.Input
        fluid
        placeholder="Secondary Phone"
        name="phone_secondary"
        onChange={handleChange}
        value={inputs.phone_secondary}
      />
      {btnLoading==true ? (<Button color="blue"  floated="right" loading >
        Apply Changes
      </Button>) : (<Button color="blue"  floated="right" onClick={handleSubmit} >
        Apply Changes
      </Button>)}
      
    </fieldset>
  );
};
export default BasicInfo;
