import { color } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Divider, List } from "semantic-ui-react";
import api from "../api";

const CategoryFilter = ({categories, catid}) => {
  // console.log(categories);
  if (categories) {
    return (
      <div>
        <div className="category-filter">
          <List as="ol" key="parent-cats">
            {categories.map((pcats) => (
              <List.Item as="li" value="" key={pcats.id}>
                <h4><a href={`/shop/${pcats.id}`}>{pcats.name}</a></h4>
                <List key="child">
                  {pcats.child && pcats.child.length ? pcats.child.map((ccats) => (
                    <List.Item key={ccats.id} className="childcats"><a href={`/shop/${ccats.id}`} pname={pcats.name} id={'catid'+ccats.id} className={ccats.id == catid ? 'cat-selected links':'links'} >{ccats.name}</a>
                    {ccats.child && ccats.child.length ? ccats.child.sort((a, b) => a.name.localeCompare(b.name)).map((cccats)=>(
                      <List.Item key={cccats.id} className="childcats2"><a href={`/shop/${cccats.id}`} pname={ccats.name} id={'catid'+cccats.id} className={cccats.id == catid ? 'cat-selected links':'links'} >{cccats.name}</a></List.Item>
                    )):null}
                    </List.Item>
                  )):null}
                </List>
                <Divider />
              </List.Item>
            ))}
          </List>
        </div>
      </div>
    );
  }
  //return null;
};
export default CategoryFilter;

//@llAfM4y