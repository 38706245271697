import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { LOCAL_STORAGE_KEY_USER } from "../config";
import BasicInfo from "./edit/BasicInfo";
import BillingAddress from "./edit/BillingAddress";
import ChangePassword from "./edit/ChangePassword";
import api from "../api";

const Accounts = () => {
  const [user, setUser] = useState([]);
  const [address, setAddress] = useState([]);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    const theUser = await api.get(`users/${localUser.user_id}`);
    console.log("theUser.data", theUser.data);
    if (theUser.status === 200 && theUser.data) {
      setUser(theUser.data);
      setAddress(theUser.data.address[0]);
    }
  };
  console.log("localUser.user_id", localUser.user_id);
  console.log("User", user);
  console.log("User address", address);
  return (
    <Grid textAlign="left" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 800 }}>
        <Header as="h2" color="red" textAlign="left">
          Edit My Account
        </Header>
        <Form size="large" inline>
          <BasicInfo localUser={localUser} user={user} />
          <BillingAddress localUser={localUser} address={address} />
          <ChangePassword localUser={localUser} />
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default Accounts;
