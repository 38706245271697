import React, {useEffect, useState} from "react"
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Segment, Button, Icon } from 'semantic-ui-react';
import { APIBASEURL, CUSTOMSTYLES } from "../../config";

const CustomerList = () => {
    const [customers, getCustomers] = useState('');
    useEffect(()=>{
        getAllCustomers();
    },[]);
    const getAllCustomers = () => {
        axios.get(`${APIBASEURL}users`).then((response) => {
            const allCustomers = response.data;
            getCustomers(allCustomers);
        }).catch(error => console.log(`Error: ${error}`))
    }
    
   
    const columns = [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Company",
        selector: (row) => row.customers.company_name,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Phone",
        selector: (row) => row.customers.phone_primary,
        sortable: true,
      },
      {
        name: "Orders",
        selector: (row) => row.orders_count,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div>
            <Button.Group icon>
              <Button as='a' href={`/admin/customers/${row.id}`}>
                <Icon name="eye" />
              </Button>
            </Button.Group>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        grow: "2",
      },
    ];
    const data =[];
    const clickHandler = (state) => {
        console.log('clicked');
        console.log(state.target.id);
      };
    return (
        <Segment>
            <DataTable
                title="Customers"
                columns={columns}
                data={customers}
                pagination
                responsive
                fixedHeaderScrollHeight="100px"
                customStyles={CUSTOMSTYLES}
            />
        </Segment>
    );
}

export default CustomerList