import { Grid } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import CategoryList from "../categories/list";
import CategoryEdit from "../categories/add";

const Categories = () => {
  const { catid } = useParams();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCategories();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  async function getCategories() {
    setLoading(true);
    const parent = await api.get("category/getall");
    const parentData = parent.data;
    setCategories(parentData);
    //console.log(parentData);
    let cat = parentData.filter((item)=>{
        return item.id == catid;
    });
    if(cat && cat.length > 0)
      setCategory(cat[0]);
    //console.log("Category: ",cat);
    setLoading(false);
  }
  
  return (
    <div>
      
      <Grid
        columns={2}
        divided
        style={{ margin: "1em 0em 0em", padding: "1em 0em" }}
      >
        <Grid.Column width={10} className="ui left">
        {loading ? <div className="ui active centered inline loader"></div> : <CategoryList categories={categories} /> } 
        </Grid.Column>
        <Grid.Column width={6}>
          <CategoryEdit category={category} categories={categories} catid={catid} />
        </Grid.Column>
      </Grid>
    </div>
  );
};
export default Categories;