import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Container
} from "semantic-ui-react";
import validator from "validator";
import api from "../api";
import { LOCAL_STORAGE_KEY_USER } from "../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const Login = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const [loginError, setLoginError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  const navigate = useNavigate();

  const validateEmail = (email) => {
    if (email && validator.isEmail(email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Enter valid Email!");
      return false;
    }
  };
  const validatePassword = (password) => {
    if (password && !validator.isEmpty(password)) {
      setPasswordError("");
      return true;
    } else {
      setPasswordError("Enter valid Password!");
      return false;
    }
  };
  async function userLogin() {
    let usr = [];
    if (validateEmail(inputEmail) && validatePassword(inputPassword)) {
      usr = await api.post("userauth/login", {
        email: inputEmail,
        password: inputPassword,
      });
      console.log(usr);
      if (usr.status === 200 && usr.data.status === true) {
        let userData = {
          isLoggedin: true,
          user_id: usr.data.data.id,
          user_role: usr.data.data.role_id,
          user_name: usr.data.data.name,
          user_email: usr.data.data.email,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY_USER, JSON.stringify(userData));
        if (redirectTo) {
          navigate(redirectTo);
        } else {
          if (userData.user_role == 1) {
            navigate("/admin/dashboard");
          } else if (userData.user_role == 2) {
            navigate("/user/dashboard");
          } else {
            navigate("/");
          }
        }
      } else {
        setLoginError("Invalid Credentials! Try again.");
      }
    }
  }
  console.log("loginError", loginError);
  return (
    <Container text style={{ marginTop: '7em' }}>
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="red" textAlign="center">
          Log-in to your account
        </Header>
        {loginError != "" ? <Message color="red">{loginError}</Message> : ""}

        <Form size="large">
          <Segment stacked>
            {emailError != "" ? (
              <Message color="red">{emailError}</Message>
            ) : (
              ""
            )}
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              type="email"
              id="userEmail"
              value={inputEmail}
              onInput={(e) => setInputEmail(e.target.value)}
            />
            {passwordError != "" ? (
              <Message color="red">{passwordError}</Message>
            ) : (
              ""
            )}
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              id="userPassword"
              onInput={(e) => setInputPassword(e.target.value)}
            />

            <Button color="blue" fluid size="large" onClick={userLogin}>
              Login
            </Button>
            <p style={{marginTop:"40px"}}>
            <a href="/user/forgotpassword">Forgot Password?</a>
            </p>
            
          </Segment>
        </Form>
        <Message>
          New to us?{" "}
          <a href={`/user/signup?redirectTo=${redirectTo}`}>Sign Up</a>
        </Message>
      </Grid.Column>
    </Grid>
    </Container>
  );
};

export default Login;
