import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Message
} from "semantic-ui-react";
import api from "../../api";
import validator from "validator";
import { LOCAL_STORAGE_KEY_USER } from "../../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const BasicInfo = ({localUser, address}) => {
    console.log("address",address);
    const [btnLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [inputs, setInputs] = useState([]);
  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    const theUser = await api.get(`users/${localUser.user_id}`);
    console.log("theUser.data", theUser.data);
    if (theUser.status === 200 && theUser.data) {
      let usr = theUser.data?.address;
      if(usr.length > 0){
        let ua = usr[0];
        setInputs({
          address_id: ua.id,
          user_id: ua.user_id,
          contact_name: ua.contact_name,
          street: ua.street,
          apartment: ua.apartment,
          city: ua.city,
          state: ua.state,
          zip: ua.zip,
        });
      }else{
        setInputs({
          address_id: "",
          user_id: theUser.data.id,
          contact_name: "",
          street: "",
          apartment: "",
          city: "",
          state: "",
          zip: "",
        });
      }
    }
  };
  const [validError, setValidError] = useState({
    contact_name: "",
    street: "",
    apartment: "",
    city: "",
    state: "",
    zip: "",
  });
  
  const [success, setSuccess] = useState("");
  const handleChange = (event, data) => {
    if (data) {
      const name = data.name;
      const value = data.value;
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    console.log("inputs", JSON.stringify(inputs));
    let i = 0;
    Object.keys(inputs).map(async (key) => {
      //console.log("key " + i + ":", key);
      switch (key) {
        case "street":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({
                ...values,
                [key]: "Invalid street address",
              }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "city":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({ ...values, [key]: "Invalid city" }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "state":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({ ...values, [key]: "Invalid state" }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "zip":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({ ...values, [key]: "Invalid zip" }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        default:
          break;
      }
      i++;
    });
    if (
      validError.street === "" &&
      validError.city === "" &&
      validError.state === "" &&
      validError.zip === ""
    ) {
      Object.keys(inputs).map((key) => {
        formData.append(key, inputs[key]);
      });
      //formData.append('address_id',address.id);
      const response = await api.post("userauth/updatebillingaddress", formData);
      console.log("response", response.data);
      if (response.status === 200 && response.data.status === true) {
        setSuccess(response.data.msg);
        setError("");
      } else {
        setError("Invalid input, failed to create user account");
        setSuccess("");
      }
    }
    setLoading(false);
  };
  return (
    <fieldset>
      <legend>Billing Address</legend>
      {success === "" ? "" :(<Message color="green">{success}</Message>)}
      {error ==="" ?"" :(<Message color="red">{error}</Message>)}
      <label>Contact Name</label>
      <Form.Input
        fluid
        placeholder="Contact Name"
        name="contact_name"
        onChange={handleChange}
        value={inputs?.contact_name}
      />
      <label>
        Street Address <span className="required">*</span>
      </label>
      <Form.Input
        fluid
        placeholder="Street Address"
        name="street"
        onChange={handleChange}
        value={inputs?.street}
      />
      {validError.street != "" ? (
        <Message color="red">{validError.street}</Message>
      ) : (
        ""
      )}
      <label>Apartment/Unit/Lot</label>
      <Form.Input
        fluid
        placeholder="Apartment/Unit/Lot"
        name="apartment"
        onChange={handleChange}
        value={inputs?.apartment}
      />
      <label>
        City <span className="required">*</span>
      </label>
      <Form.Input
        fluid
        placeholder="City"
        name="city"
        onChange={handleChange}
        value={inputs?.city}
      />
      {validError.city != "" ? (
        <Message color="red">{validError.city}</Message>
      ) : (
        ""
      )}
      <label>
        State <span className="required">*</span>
      </label>
      <Form.Input
        fluid
        placeholder="State"
        name="state"
        onChange={handleChange}
        value={inputs?.state}
      />
      {validError.state != "" ? (
        <Message color="red">{validError.state}</Message>
      ) : (
        ""
      )}
      <label>
        Zip Code <span className="required">*</span>
      </label>
      <Form.Input
        fluid
        placeholder="Zip Code"
        name="zip"
        onChange={handleChange}
        value={inputs?.zip}
      />
      {validError.zip != "" ? (
        <Message color="red">{validError.zip}</Message>
      ) : (
        ""
      )}
      {btnLoading==true ? (<Button color="blue"  floated="right" loading >
        Apply Changes
      </Button>) : (<Button color="blue"  floated="right" onClick={handleSubmit} >
        Apply Changes
      </Button>)}
    </fieldset>
  );
};
export default BasicInfo;
