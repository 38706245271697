import DataTable from "react-data-table-component";
import { CUSTOMSTYLES} from "../../config";
import { Button, Icon,Confirm } from "semantic-ui-react";

const CategoryList = ({ categories }) => {
  console.log("Categories", categories);
  
  const deleteCategory = (catid) => {
    alert('Are you sure?');
    console.log("catid",catid);
    <Confirm
          open={true}
          onConfirm={handleConfirm()}
        />
  }
function handleConfirm(){
  console.log("Category Delete: ");
}
  const columns = [
    {
      name: "ID",
      selector: (row) => row?.id,
      sortable: true,
    },
    {
      name: "Category Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Parent Pategory Name",
      selector: (row) => row?.pcatname,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (<div>
        <Button.Group icon>
          <Button as="a" href={`/admin/categories/${row?.id}`}>
            <Icon name="pencil" />
          </Button>
          <Button as="a" href={`/admin/categories/delete/${row?.id}`}>
            <Icon name="delete" />
          </Button>
        </Button.Group>
        
      </div>),
      sortable: true,
    },
  ];

  if (categories) {
    return (
      <div>
        <h2>Categories</h2>
        <DataTable
        columns={columns}
        data={categories}
        pagination
        responsive
        fixedHeaderScrollHeight="100px"
        customStyles={CUSTOMSTYLES}
      />
      </div>
    );
  }else{
    return (
      <div>
        No category found!
      </div>
    )
  }
};
export default CategoryList;
