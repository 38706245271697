import { Form, Grid, Button } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate, useLocation } from "react-router";

const CategoryEdit = ({ categories, category, catid }) => {
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [pcategory, setpCategory] = useState(0);

  useEffect(()=>{
    setCategoryName(category.name || "");
    setpCategory(category.parent_id || 0);
  },[category]);

  const handlepcatChange = (e) =>{
    console.log('e.target.value',e.target.value);
    setpCategory(e.target.value);
  }
  let navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("cat_id", category?.id || 0);
    formData.append("category_name", categoryName);
    formData.append("parent_category", pcategory);
    try{
      const response = await api.post("categories", formData);
      console.log(response.data);
      if (response.status === 200 && response.data.status === true) {
        setSuccess("Category Addded Successfully");
        navigate('/admin/categories');
      } else {
        setError("Invalid Input, Failed to Add Category");
      }
    }catch(e){
      setError("Invalid Input, Operation Failed");
    }
    
  };
  console.log("category",category);
  return (
    <div>
      <h2>{catid? 'Update' : 'Add New'} Category</h2>
      <Form>
        <Grid celled="internally">
          <Grid.Row>
            <Grid.Column width={16}>
              <Form.Field>
                <label>Category Name</label>
                <input
                  placeholder="Category Name"
                  name="category_name"
                  value={categoryName}
                  onChange={(e)=>{setCategoryName(e.target.value)}}
                />
              </Form.Field>
              <Form.Field>
                <label>Parent Category</label>
                <select name="parent_category" onChange={handlepcatChange} value={pcategory}>
                  <option value={0} key={0}>None</option>
                  {categories.map((d) => (
                    <option value={d.id} key={d.id}>
                      {d.name} {d.pcatname != null ? ' ('+d.pcatname+')' : ''}
                    </option>
                  ))}
                </select>
              </Form.Field>
              <Button primary size="medium" type="submit" onClick={handleSubmit}>
                Save
              </Button>
              <Button secondary size="medium" type="a" href="/admin/categories">
                Cancel
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default CategoryEdit;
