import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import api from "../../api";
import validator from "validator";
import { LOCAL_STORAGE_KEY_USER } from "../../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const ChangePassword = ({localUser}) => {
  const [btnLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [inputs, setInputs] = useState({
    new_password: "",
    repeat_password: ""
  });
  const [success, setSuccess] = useState("");
  const handleChange = (event, data) => {
    if (data) {
      const name = data.name;
      const value = data.value;
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    console.log("inputs", JSON.stringify(inputs));
    let i = 0;

    Object.keys(inputs).map((key) => {
      formData.append(key, inputs[key]);
    });
    formData.append("user_id", localUser.user_id);
    const response = await api.post("userauth/updatepassword", formData);
    console.log("response", response.data);
    if (response.status === 200 && response.data.status === true) {
      setSuccess(response.data.msg);
      setError("");
      setInputs({
        new_password: "",
        repeat_password: ""
      });
    } else {
      let erors = response?.data?.errors;
      if(erors){
        let err ="";
        Object.keys(erors).map((key) => {
          console.log("key",key);
          err += `${erors[key]} \n`;
        });
        
        setError(err);
      }else{
        setError("Invalid input");
      }
      
      setSuccess("");
    }
    setLoading(false);
  };
  return (
    <fieldset>
      <legend>Change Password</legend>
      {success === "" ? "" :(<Message color="green">{success}</Message>)}
      {error ==="" ?"" :(<Message color="red">{error}</Message>)}
      <label>New Password</label>
      <Form.Input
        fluid
        placeholder=""
        name="new_password"
        onChange={handleChange}
        value={inputs.new_password}
        type="password"
      />
      <label>
        Repeat Password <span className="required">*</span>
      </label>
      <Form.Input
        fluid
        placeholder=""
        name="repeat_password"
        onChange={handleChange}
        value={inputs.repeat_password}
        type="password"
      />
      
      <Button color="blue" floated="right" onClick={handleSubmit}>
        Apply Changes
      </Button>
    </fieldset>
  );
};
export default ChangePassword;
