import React, {useState, useEffect} from 'react'
import { Button, Form, Grid, Table, Image, Segment } from 'semantic-ui-react';
import api from '../api';
import { LOCAL_STORAGE_KEY_USER } from "../config";
import Orders from './Orders';

const Dashboard = () => {
    const [customer, setCustomer] = useState([]);
    const [customerAddress, setCustomerAddress] = useState([]);
    const [localUser, setLocalUser] = useState(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
      );
    useEffect(()=>{
        getCustomers();
    },[]);
    const getCustomers = async () => {
        console.log("localUser",localUser);
        const customer = await api.get(`users/${localUser.user_id}`);
        if(customer.status == 200 && customer.data){
            console.log('customer.data',customer.data);
            setCustomer(customer.data);
            customer.data.address.length !==0 ? setCustomerAddress(customer?.data?.address[0]) : setCustomerAddress({'street':'','apartment':'','city':'','state':'','zip':''});
            console.log('customer.Address',customer.data.address ?? customer.data.address);
        }
    }
    let i= 0;
    return (
        <Grid textAlign='center'>
            <Grid.Column style={{ textAlign: 'left' }}>
                <Form>
                    <fieldset key={i++}>
                        <legend>Details</legend>
                        <Grid divided='vertically' key={i++}>
                            <Grid.Row columns={2} key={i++}>
                                <Grid.Column key={i++}>
                                    <Table compact className='noborder' key={i++}>
                                        <Table.Body key={i++}>
                                            <Table.Row key={i++}>
                                                <Table.Cell key={i++} singleLine verticalAlign="top" textAlign='right'>Acccount Name</Table.Cell>
                                                <Table.Cell key={i++} colSpan="2">{customer.name}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row key={i++}>
                                                <Table.Cell singleLine verticalAlign="top" textAlign='right'>Billing Address</Table.Cell>
                                                <Table.Cell>{`${customerAddress?.street}, ${customerAddress?.city}, ${customerAddress?.state} ${customerAddress?.zip} `}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row key={i++}>
                                                <Table.Cell singleLine verticalAlign="top" textAlign='right'>Primary Phone</Table.Cell>
                                                <Table.Cell>{customer?.customers?.phone_primary}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row key={i++}>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                                <Grid.Column key={i++}>
                                    <Table compact className='noborder' key={i++}>
                                        <Table.Body key={i++}>
                                            <Table.Row>
                                                <Table.Cell singleLine verticalAlign="top" textAlign='right'>Email</Table.Cell>
                                                <Table.Cell>{customer?.email}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row key={i++}>
                                                <Table.Cell singleLine verticalAlign="top" textAlign='right'>ATF License No</Table.Cell>
                                                <Table.Cell>Not on file, expired, or invalid.</Table.Cell>
                                            </Table.Row>
                                            <Table.Row key={i++}>
                                                <Table.Cell textAlign='right'>Expiration Date</Table.Cell>
                                                <Table.Cell></Table.Cell>
                                            </Table.Row>
                                            <Table.Row key={i++}>
                                            <Table.Cell colSpan="2" textAlign='right'><a href="/user/edit-account" >Edit My Account</a></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </fieldset>
                    
                    <fieldset key={i++}>
                        <legend> Cumulative Total & Volume Discounts </legend>
                        <p>Cumulative total and discount message:   Spending more than $100,000 annually in display firework purchases?  If so, call to discuss options for further savings opportunities.</p>
                    </fieldset>
                    <fieldset key={i++}>
                        <legend> Order History </legend>
                        <Orders size="5"/>
                    </fieldset>
                </Form>
            </Grid.Column>
        </Grid>
    );
}

export default Dashboard