import "./App.css";
import { Routes, Route } from "react-router-dom";
import Frontend from "./layout/Frontend";
import Backend from "./layout/Backend";
import UserDashboard from "./layout/UserDashboard";
import Home from "./shop/home";
import Shop from "./shop/Shop";
import Cart from "./shop/Cart";
import Logout from "./users/Logout";
import Login from "./users/Login";
import Signup from "./users/Signup";
import Shipping from "./shop/checkout/Shipping";
import Payment from "./shop/checkout/Payment";
import Confirm from "./shop/checkout/Confirm";
import Review from "./shop/checkout/Review";
import Dashboard from "./users/dashboard";
import AdminDashboard from "./admin/AdminDashboard";
import CustomerList from "./admin/customers/list";
import CustomerDetails from "./admin/customers/details";
import OrderList from "./admin/orders/list";
import OrderEdit from "./admin/orders/edit";
import OrderDetails from "./admin/orders/details";
import ProductList from "./admin/products/list";
import ProductDetails from "./admin/products/details";
import ProductAdd from "./admin/products/add";
import ProductEdit from "./admin/products/edit.jsx";
import Orders from "./users/Orders";
import Accounts from "./users/Accounts";
import ForgotPassword from "./users/ForgotPassword";
import Categories from "./admin/categories/cats";
import CategoryDelete from "./admin/categories/delete";
import PrintOrders from "./admin/orders/print";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Frontend />}>
          <Route index element={<Home />} forceRefresh={true}/>
          <Route path="/shop">
            <Route index element={<Shop />} forceRefresh={true}/>
            <Route path=":catid" element={<Shop />} forceRefresh={true}/>
          </Route>
          <Route path="/cart" element={<Cart />} />
          <Route path="/user/login" element={<Login />} />
          <Route path="/user/signup" element={<Signup />} />
          <Route path="/user/logout" element={<Logout />} />
          <Route path="/user/forgotpassword" element={<ForgotPassword />} />
          <Route path="/checkout/shipping" element={<Shipping />} />
          <Route path="/checkout/payment" element={<Payment />} />
          <Route path="/checkout/confirm" element={<Confirm />} />
          <Route path="/checkout/review" element={<Review />} />
          <Route path="/checkout/orderdetails/:id" element={<OrderDetails key="odk" />} />
        </Route>
        <Route path="/" element={<UserDashboard />}>
          <Route path="/user/dashboard" element={<Dashboard />}/>
          <Route path="/user/orders" element={<Orders title="My Orders" />}/>
          <Route path="/user/edit-account" element={<Accounts />}/>
          <Route
            path="/user/orders/:id"
            element={<OrderDetails key="odk" />}
          />
        </Route>

        <Route path="/" element={<Backend />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/customers" element={<CustomerList />} />
          <Route path="/admin/customers/:id" element={<CustomerDetails />} />
          <Route path="/admin/orders" element={<OrderList />} />
          <Route path="/admin/categories">
            <Route index element={<Categories />} forceRefresh={true}/>
            <Route path=":catid" element={<Categories />} forceRefresh={true}/>
          </Route>
          <Route path="/admin/categories/delete/:catid" element={<Categories />} />
            
          <Route
            path="/admin/orders/:id"
            element={<OrderDetails key="odk" />}
          />
          <Route
            path="/admin/orders/edit/:id"
            element={<OrderEdit key="odk" />}
          />
          <Route path="/admin/products" element={<ProductList />} />
          <Route path="/admin/products/:id" element={<ProductDetails />} />
          <Route path="/admin/products/add" element={<ProductAdd />} />
          <Route path="/admin/products/edit/:id" element={<ProductEdit />} />
        </Route>
        <Route
            path="/print-orders/:id"
            element={<PrintOrders />}
          />
      </Routes>
      
    </div>
  );
}

export default App;
