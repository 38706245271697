import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Checkbox,
  Table,
  Message,
  TextArea,
} from "semantic-ui-react";
import CartSteps from "./CartSteps";
import {
  currencyFormat,
  weightFormat,
  LOCAL_STORAGE_KEY_CART,
  LOCAL_STORAGE_KEY_USER,
  LOCAL_STORAGE_KEY_CART_SUMMERY,
  LOCAL_STORAGE_KEY_CHECKOUT,
} from "../../config";
import api from "../../api";
import { useNavigate, useLocation } from "react-router";

var i = 0;
var listPriceTotal = 0;
var volumeDiscountSaving = 0;
var subtotal = 0;
var salesTax = 0;
var cashDiscount = 0;
var total = 0;

const pickup = {
  location: "Ashu Tosh",
  delivery_date: "87, Dalal Street Carlifornia, CA 93221",
  email: "test@hello.com",
  phone: "+1919191929999",
};
const payment = {
  method: "Credit Card",
};

const Confirm = () => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CART)) ?? []
  );
  const [cartSummery, setCartSummery] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CART_SUMMERY)) ?? []
  );
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  const [checkout, setCheckout] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CHECKOUT)) ?? []
  );
  const [userDetails, setUserDetails] = useState([]);
  async function getUser() {
    const pc = await api.get(`users/${localUser.user_id}`);
    if ((pc.status = 200 && pc.data)) {
      let ua = pc?.data?.address[0];
      let ud = {
        full_name: pc?.data?.name,
        email: pc?.data?.email,
        phone: pc?.data?.customers?.phone_primary,
        billing_address: `${ua?.street}, ${ua?.city}, ${ua?.state} ${ua?.zip}`,
      };
      setUserDetails(ud);
    }
  }
  const [confirmOrder,setConfirmOrder] = useState(false);
  useEffect(() => {
    getUser();
    console.log("cartSummery",cartSummery);
  }, []);
  const [atfLicense, setATFLicense] = useState(false);
  const handleATFLicence = (event) => {
    setATFLicense((atfLicense) => !atfLicense);
  };
  const [orderComment, setOrderComment] = useState("");
  // const handleOrderComment = (event) => {
  //   setOrderComment(event.target.value);
  //   console.log("orderComment",orderComment);
  // }
  const listItems = cartItems.map((item) => (
    <Table.Row key={i++}>
      <Table.Cell>{item.order_qty}</Table.Cell>
      <Table.Cell>{item.part_number}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>{currencyFormat(item.price)}</Table.Cell>
      <Table.Cell>
        {currencyFormat(parseInt(item.order_qty) * parseFloat(item.price))}
      </Table.Cell>
    </Table.Row>
  ));
  const orderNoteRef = useRef(null);
  let navigate = useNavigate();
  const handlePlaceOrder = async (event) => {
    setConfirmOrder(true);
    event.preventDefault();
    setOrderComment(orderNoteRef.current.value);
    if (!atfLicense) {
      alert("Please accept ATF License terms");
      setConfirmOrder(false);
    } else {
      const formData = new FormData();
      formData.append("user_id", localUser.user_id);
      formData.append("total_price", cartSummery.total_price);
      formData.append("discount_price", cartSummery.discount_price);
      formData.append("discount_percentage", cartSummery.discount_percentage);
      formData.append("subtotal", cartSummery.subtotal);
      formData.append("salestax", cartSummery.salestax);
      formData.append("grand_total", cartSummery.grand_total);
      formData.append(
        "creditcard_discount",
        checkout?.payment_method == 1 || checkout?.payment_method == 2
          ? 0
          : ((cartSummery.subtotal + cartSummery.salestax) * 3) / 100
      );
      formData.append("total_volume", cartSummery.total_volume);
      formData.append("total_weight", cartSummery.total_weight);
      formData.append("net_explosive_weight", cartSummery.net_explosive_weight);
      let checkoutData = {
        pickup_type_id: checkout?.shipping?.pickup_type_id,
        pickup_by_id: checkout?.shipping?.pickup_by_id,
        address_id: checkout?.shipping?.address_id,
        delivery_location_type_id:
          checkout?.shipping?.delivery_location_type_id,
        payment_method: checkout?.payment_method,
        shipment_date: checkout?.shipping?.shipment_date,
        pickup_by_name: checkout?.shipping?.pickup_by_name,
        event_name_Location: checkout?.shipping?.event_name_Location,
        order_note: orderComment ? orderComment : "",
        atf_concent: atfLicense ? "YES" : "NO",
        order_status: "pending",
        order_confirm_date: "",
      };

      formData.append("items", JSON.stringify(cartItems));
      formData.append("checkout", JSON.stringify(checkoutData));
      formData.append("address", JSON.stringify(checkout?.shipping?.address));
      try {
        //console.log("formData",formData.get());
        const response = await api.post("orders", formData);
        console.log("response", response);
        if (response.status == 200) {
          localStorage.removeItem(LOCAL_STORAGE_KEY_CART);
          localStorage.removeItem(LOCAL_STORAGE_KEY_CART_SUMMERY);
          localStorage.removeItem(LOCAL_STORAGE_KEY_CHECKOUT);
          navigate("/checkout/review?order_id=" + response.data.order_id);
        } else {
          alert("Unable to process order, try after some time");
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    setConfirmOrder(false);
  };
  return (
    <Grid textAlign="center">
      <Grid.Column style={{ maxWidth: 970, textAlign: "left" }}>
        <CartSteps mySteps="3" />
        <Form>
          <fieldset key={i++}>
            <legend>Your Order:</legend>
            <Grid divided="vertically" key={i++}>
              <Grid.Row columns={2} key={i++}>
                <Grid.Column key={i++}>
                  <Table compact className="noborder" key={i++}>
                    <Table.Body key={i++}>
                      <Table.Row key={i++}>
                        <Table.Cell
                          key={i++}
                          singleLine
                          verticalAlign="top"
                          textAlign="right"
                        >
                          Acccount Name
                        </Table.Cell>
                        <Table.Cell key={i++} colSpan="2">
                          {userDetails.full_name}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row key={i++}>
                        <Table.Cell
                          singleLine
                          verticalAlign="top"
                          textAlign="right"
                        >
                          Billing Address
                        </Table.Cell>
                        <Table.Cell>{userDetails.billing_address}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          singleLine
                          verticalAlign="top"
                          textAlign="right"
                        >
                          Email
                        </Table.Cell>
                        <Table.Cell>{userDetails.email}</Table.Cell>
                      </Table.Row>
                      <Table.Row key={i++}>
                        <Table.Cell
                          singleLine
                          verticalAlign="top"
                          textAlign="right"
                        >
                          Primary Phone
                        </Table.Cell>
                        <Table.Cell>{userDetails.phone}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column key={i++}>
                  <Table compact className="noborder" key={i++}>
                    <Table.Body key={i++}>
                      <Table.Row key={i++}>
                        <Table.Cell
                          singleLine
                          verticalAlign="top"
                          textAlign="right"
                        >
                          Pickup
                        </Table.Cell>
                        <Table.Cell colSpan="2">
                          {checkout?.shipping?.pickup_by_name}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row key={i++}>
                        <Table.Cell
                          singleLine
                          verticalAlign="top"
                          textAlign="right"
                        >
                          Must Have Date
                        </Table.Cell>
                        <Table.Cell>
                          {checkout?.shipping?.shipment_date}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row key={i++}>
                        <Table.Cell colSpan="2" textAlign="right">
                          <a href="/checkout/shipping">Edit</a>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row key={i++}>
                        <Table.Cell
                          singleLine
                          verticalAlign="top"
                          textAlign="right"
                        >
                          Payment
                        </Table.Cell>
                        <Table.Cell>{checkout?.payment_method_name}</Table.Cell>
                      </Table.Row>

                      <Table.Row key={i++}>
                        <Table.Cell colSpan="2" textAlign="right">
                          <a href="/checkout/payment">Edit</a>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Table
              key={i++}
              compact
              celled
              striped
              style={{ margin: "5em 0em 0 em" }}
            >
              <Table.Header key={i++}>
                <Table.Row key={i++}>
                  <Table.HeaderCell>Qty</Table.HeaderCell>
                  <Table.HeaderCell>Part Number</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Your Price</Table.HeaderCell>
                  <Table.HeaderCell>Subtotal</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>{listItems}</Table.Body>
              <Table.Footer>
                <Table.Row style={{ borderTop: "1px solid #ccc" }}>
                  <Table.Cell
                    colSpan="5"
                    style={{ borderTop: "1px solid rgba(34,36,38,.1)" }}
                  >
                    <Table compact className="noborder" key={i++}>
                      <Table.Body textAlign="right">
                        <Table.Row key={i++}>
                          <Table.Cell colSpan="4" textAlign="right">
                            List Price Total
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {currencyFormat(cartSummery?.subtotal)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row key={i++}>
                          <Table.Cell colSpan="4" textAlign="right">
                            Volume Discount Savings
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {currencyFormat(cartSummery?.discount_price)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row key={i++}>
                          <Table.Cell colSpan="4" textAlign="right">
                            Subtotal
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {currencyFormat(cartSummery?.subtotal)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row key={i++}>
                          <Table.Cell colSpan="4" textAlign="right">
                            Applicable Sales Tax
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {currencyFormat(cartSummery?.salestax)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row key={i++}>
                          <Table.Cell colSpan="4" textAlign="right">
                            Cash Discount Removed
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {checkout.payment_method == 1 ||
                            checkout.payment_method == 2
                              ? 0
                              : currencyFormat(
                                  ((cartSummery?.subtotal +
                                    cartSummery?.salestax) *
                                    3) /
                                    100
                                )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row key={i++}>
                          <Table.Cell colSpan="4" textAlign="right">
                            Total
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {currencyFormat(
                              checkout.payment_method == 1 ||
                                checkout.payment_method == 2
                                ? cartSummery?.grand_total
                                : cartSummery?.creditcard_discount
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row key={i++}>
                          <Table.Cell colSpan="5" textAlign="right">
                            An additional shipping charge will be applied.
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </fieldset>
          <fieldset key={i++}>
            <legend> ATF License Information </legend>
            <Message
              color="yellow"
              icon="warning sign"
              content="Notice: A valid ATF license is required to take posession of regulated products.
    To place this order, you must certify below that we will recieve a valid copy of your ATF license when you take posession of your order."
            />
            <Checkbox
              label="I acknowledge that I must provide Munnerlyn Pyro with a copy of my ATF License with an original signature, before taking possession of my order."
              checked={atfLicense}
              onChange={handleATFLicence}
            />
          </fieldset>
          <fieldset key={i++}>
            <legend> Comments </legend>
            <TextArea
              placeholder="Tell us more about your order"
              id="order_summery"
              ref={orderNoteRef}
            />
          </fieldset>
          <div key={i++}>
            <Button
              floated="right"
              primary
              onClick={handlePlaceOrder}
              key={i++}
              disabled={confirmOrder}
            >
              Place Order
            </Button>
            <Button floated="left" as="a" href="/checkout/payment" key={i++}>
              Go Back To: Payment
            </Button>
          </div>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default Confirm;
