import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Menu, Header, Container, Divider, Responsive } from "semantic-ui-react";
import api from "../api";
import CategoryFilter from "./CategoryFilter";

const Home = () => {
  const { catid } = useParams();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategories();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  async function getCategories() {
    const parent = await api.get("categories");
    const parentData = parent.data;
    setCategories(parentData);
  }

  return (
    <div>
      <Grid
        columns={2}
        divided
        style={{ margin: "1em 0em 0em", padding: "1em 0em" }}
      >
        <Grid.Row only='computer'>
          <Grid.Column width={3} className="ui left" style={{}}>
            <CategoryFilter categories={categories} catid={catid} />
          </Grid.Column>
          <Grid.Column width={13}>
            <HomePage categories={categories} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only='mobile'>
          <Grid.Column width={6} className="ui left" style={{}}>
            <CategoryFilter categories={categories} catid={catid} />
          </Grid.Column>
          <Grid.Column width={10}>
            <HomePage categories={categories} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
const HomePage = ({ categories }) => {
  console.log("categories", categories);
  let i = 0;
  return (
    <Container>
      <Header as="h1">Wholesale Orders: </Header>
      <p>
        Munnerlyn Pyrotechnics is excited to start offering wholesale display
        fireworks to ATF licensed operators! We also carry a selection of 1.4G
        pro and consumer products.{" "}
      </p>
      <p>
        Munnerlyn Pyrotechnics is excited to provide our online ordering system
        allowing visibility to product in stock along with estimated arrival
        dates for future availability! Our online inventory is updated instantly
        after each order allowing for confidence in your selections being in
        stock. You can also place orders for future availability stock, just
        keep in mind that the estimated dates for arrival are just estimates and
        can change.
      </p>
      <p>
        We believe in providing amazing prices and high quality products. Our
        vision is to provide a wide range of products for both display and
        consumer operators. For customers spending greater than $10,000 in a
        calendar year we provide a 10% discount. For customers spending over
        $100,000 annually a 20% total discount will be applied to your order.
        For customers that are placing orders less than these amounts, but will
        over the course of the year spend in excess of these discount tiers, the
        discount will be given to historical orders. This retrospective discount
        is a manual process, so do not expect to see this retrospective discount
        on invoices after tiers are achieved. We will go back and correct prior
        to payment. <strong>All prices on our website reflect the price without
        discounts being applied.</strong>
      </p>

      <p>
        Please note that invoices will reflect a charge for sales tax. For many
        of our customers sales tax exemption is common. Sales tax will be
        removed after orders are received for all clients that have provided a
        sales tax exemption. For clients that are the end user, sales tax will
        apply without a provided exemption.
      </p>
      {categories.map((category) => {
        return <CatShow category={category} key={i++} />;
      })}
    </Container>
  );
};
const CatShow = ({ category }) => {
  return (
    <Container style={{ marginTop: "40px" }}>
      <Header as="h3">{category.name}</Header>
      <Header as="h4">Shop by type</Header>
      <Menu text className="homecatlistmenu">
        {category.child ? category?.child?.map((cc) => {
          return (
            <Menu.Item
              name={cc.name}
              as="a"
              href={`/shop/${cc.id}`}
              key={cc.id}
              className="subcatseparaed"
              color="black"
            />
          );
        }): ""}
      </Menu>
      <Divider />
    </Container>
  );
};
export default Home;
