import React,{useState} from 'react'
import { Button, Form, Grid, Table, Image, Segment } from 'semantic-ui-react';
import CartSteps from './CartSteps';
import { useNavigate, useSearchParams } from "react-router-dom";
import { LOCAL_STORAGE_KEY_USER, currencyFormat } from "../../config";
import { useEffect } from 'react';
import api from '../../api';
var i = 0;
var listPriceTotal = 0;
var volumeDiscountSaving = 0;
var subtotal = 0;
var salesTax = 0;
var cashDiscount = 0;
var total = 0;

const userDetails = {
    full_name: 'Ashu Tosh',
    billing_address: '87, Dalal Street Carlifornia, CA 93221',
    email: 'test@hello.com',
    phone: '+1919191929999'
};
const pickup = {
    location: 'Ashu Tosh',
    delivery_date: '87, Dalal Street Carlifornia, CA 93221',
    email: 'test@hello.com',
    phone: '+1919191929999'
};
const payment = {
    method: 'Credit Card',
};
const Review = () => {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("order_id");
    const [localUser, setLocalUser] = useState(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
      );
    const [order, setOrder] = useState([]);
    useEffect(()=>{
        getOrder(orderId);
    },[]);
    async function getOrder(orderId){
        const myOrder = await api.get(`orders/${orderId}`);
        if(myOrder.status == 200 && myOrder.data){
            setOrder(myOrder.data.order_details);
            console.log("myOrder.data",myOrder.data.order_details);
        }
    }
    return (
        <Grid textAlign='center'>
            <Grid.Column style={{ maxWidth: 970, textAlign: 'left' }}>
                <CartSteps mySteps='4' />
                <Form>
                    <fieldset key={i++}>
                        <legend>Order: #{orderId} Confirmed</legend>
                        <Grid divided='vertically' key={i++}>
                            <Grid.Row key={i++}>
                                <Grid.Column key={i++}>
                                    <h2>Thank you for your order! <a href={`/checkout/orderdetails/${orderId}`} >View your Order #{orderId}</a></h2>
                                    <p>A confirmation message with a copy of your invoice has been emailed to {localUser.user_email}.</p>
                                    <table>
                                        <tr>
                                            <td><strong>Did you know...</strong><br />You can keep track of your orders & discount levels on your “<a href="/user/dashboard">My Account</a>” page.</td>
                                            <td><Image src='/images/discount-icon.png' size='tiny' /></td>
                                        </tr>
                                    </table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </fieldset>
                    <fieldset key={i++}>
                        <legend> Paying for your order... </legend>
                        <strong>{payment.method}</strong>
                        <p>Your total reflects a 3% discount for a cash/check payment.</p>
                        
                        <Table className='noborder' basic='very' columns={'2'}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell textAlign='right'>Total Due</Table.Cell>
                                    <Table.Cell>{currencyFormat(order.grand_total)}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell textAlign='right'>Minimum to hold order</Table.Cell>
                                    <Table.Cell>{currencyFormat(order.grand_total*25/100)}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </fieldset>
                    <fieldset key={i++}>
                        <legend> ATF License Required </legend>
                        <p>1.3G and 1.4G Display Fireworks require a valid ATF license and <a href="/New_Customer_Enrollment_to_Order_and_Receive_Explosives.pdf" target='_blank'>a new customer enrollment form</a> to be completed.   After completion of your order, please email your current ATF license to <a href='mailto:Brent@munnerlynpyro.com'>Brent@munnerlynpyro.com</a> and <a href="mailto:renee@munnerlynpyro.com">renee@munnerlynpyro.com </a></p>
                    </fieldset>
                </Form>
            </Grid.Column>
        </Grid>
    );
}

export default Review