import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Container,
  List,
  Image,
  Menu,
  Divider,
  Segment,
  Icon,
} from "semantic-ui-react";
import { LOCAL_STORAGE_KEY_USER } from "../config";

function Frontend() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );

  const [dashboard, setDashboard] = useState("");
  useEffect(() => {
    if (user && user.user_role == 1) {
      setDashboard(
        <>
        <Menu.Item name="Admin Dashboard" as="a" href="/admin/dashboard">Admin Dashboard</Menu.Item>
        <Menu.Item name="Admin Dashboard" as="a" href="/user/logout"><Icon name="power" />Logout</Menu.Item>
        </>
      );
    } else if (user && user.user_role == 2) {
      setDashboard(
        <>
        <Menu.Item name="User Dashboard" as="a" href="/user/dashboard">My Account</Menu.Item>
        <Menu.Item name="cart" as="a" href="/cart"><Icon name="cart" /></Menu.Item>
        <Menu.Item name="User Dashboard" as="a" href="/user/logout"><Icon name="power" />Logout</Menu.Item>
        </>
      );
    } else {
      setDashboard(
        <>
          <Menu.Item name="login" as="a" href="/user/login">Login</Menu.Item>
          <Menu.Item name="signup" as="a" href="/user/signup">Signup</Menu.Item>
          <Menu.Item name="cart" as="a" href="/cart"><Icon name="cart" /></Menu.Item>
        </>
      );
    }
  }, [user]);

  return (
    <div className="site-header">
      <Menu inverted>
        <Container>
          <Menu.Item as="a" href="/" header>
            <Image
              size="tiny"
              src="/images/logo.png"
              style={{ marginRight: "1.5em" }}
            />
            MunnerlynPyro Shop
          </Menu.Item>
          <Menu.Menu position="right">{dashboard}</Menu.Menu>
        </Container>
      </Menu>
      
      <Outlet />
      
      <Segment
        inverted
        vertical
        style={{ margin: "5em 0em 0em", padding: "1em 0em" }}
      >
        <Container textAlign="center">
          <List
            horizontal
            inverted
            divided
            link
            size="small"
            style={{ margin: "1em 0em 0em" }}
          >
            <List.Item as="a" href="https://munnerlynpyro.com/sitemap/">
              Site Map
            </List.Item>
            <List.Item as="a" href="https://munnerlynpyro.com/contact/">
              Contact Us
            </List.Item>
          </List>
          <Divider inverted />
          <Image centered size="small" src="/images/logo.png" />
          <p>All content © Munnerlyn Pyrotechnics {(new Date().getFullYear())} | All rights reserved</p>
        </Container>
      </Segment>
    </div>
  );
}
export default Frontend;
