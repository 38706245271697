import React, { useState, useEffect } from "react";
import { LOCAL_STORAGE_KEY_USER, LOCAL_STORAGE_KEY_CART } from "../config";
import { useNavigate } from "react-router";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    async function clearLoginData() {
      await localStorage.removeItem(LOCAL_STORAGE_KEY_CART);
      await localStorage.removeItem(LOCAL_STORAGE_KEY_USER);
      navigate("/user/login");
    }
    clearLoginData();
  }, []);
};

export default Logout;
