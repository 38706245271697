import React, { useEffect, useState } from "react";
import axios from "axios";
import Parser from "html-react-parser";
import {
  Segment,
  Button,
  Icon,
  Header,
  Form,
  Message,
  Grid,
  Select,
  Dropdown,
} from "semantic-ui-react";
import api from "../../api";
import { APIBASEURL, CUSTOMSTYLES } from "../../config";
import { useNavigate, useLocation } from "react-router";

const ProductAdd = () => {
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  const [validationErrors, setvalidationErrors] = useState([]);
  const [inputs, setInputs] = useState({});
  const stockAvailabilityOptions = [
    { key: 'SOLD', value: 'SOLD', text: 'SOLD' },
    { key: 'INSTOCK', value: 'INSTOCK', text: 'INSTOCK' },
  ];
  useEffect(() => {
    getCategories();
    getManufacturers();
  }, []);

  const getCategories = async () => {
    const response = await api.get("categories");
    const data = response.data;
    console.log("Categories:", data);
    let cats = [];
    //setCategories(data);
    data.map((d) => {
      cats.push({
        key: d.id,
        text: d.name,
        value: d.id,
      });
      if (d.child && d.child.length) {
        let childcats = d.child;
        childcats.map((cd) => {
          cats.push({
            key: cd.id,
            text: "    " + cd.name,
            value: cd.id,
          });
          if (cd.child && cd.child.length) {
            let chilcChildCats = cd.child;
            chilcChildCats
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((ccd) => {
                cats.push({
                  key: ccd.id,
                  text: "        " + ccd.name,
                  value: ccd.id,
                });
              });
          }
        });
      }
    });
    setCategories(cats);
  };
  const getManufacturers = async () => {
    const response = await api.get("manufacturers");
    const data = response.data;
    const mans = [];
    data.map((d) => {
      mans.push({
        key: d.id,
        text: d.name,
        value: d.id,
      });
    });
    setManufacturers(mans);
  };

  const handleChange = (event, data) => {
    if (data) {
      const name = data.name;
      const value = data.value;
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  let navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    console.log("Inputs", JSON.stringify(inputs));
    //formData.append("inputs", JSON.stringify(inputs));
    Object.keys(inputs).map((key) => {
      formData.append(key, inputs[key]);
    });
    
    try {
      const response = await api.post("products", formData);
      console.log(response);
      if (response.status === 200 && response.data.status === true) {
        setSuccess("Product Addded Successfully");
        navigate("/admin/products");
      } else {
        setvalidationErrors(response.data.errors);
        setError("Invalid Input, Failed to Add Product");
      }
    } catch (error) {
      console.log("Error", error.message);
      setError("Invalid Input, Failed to Add Product");
    }
  };
  return (
    <Segment>
      <h2>Add New Product</h2>
      {success ?? (
        <Message positive>
          <Message.Header>{success}</Message.Header>
        </Message>
      )}
      {error != "" ? (
          <Message negative>
            <Message.Header>{error}</Message.Header>
          </Message>
        ) : (
          ""
        )}
      <Form>
        <Grid celled="internally">
          <Grid.Row>
            <Grid.Column width={11}>
              <Form.Field>
                <label>Product Name</label>
                <input
                  placeholder="Product Name"
                  name="name"
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>SKU / Part Number</label>
                <input
                  placeholder="SKU / Part Number"
                  name="part_number"
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Product Description</label>
                <textarea
                  placeholder="Product Description"
                  name="description"
                  onChange={handleChange}
                ></textarea>
              </Form.Field>
              <Form.Field>
                <label>Video URL (YouTube URL)</label>
                <input
                  placeholder="https://www.youtube.com/watch?v=Au63DyjBQ7k"
                  name="video_url"
                  onChange={handleChange}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={5}>
              <Form.Field
                control={Select}
                label="Product Category"
                options={categories}
                placeholder="Product Category"
                name="product_category"
                onChange={handleChange}
              />
              <Form.Field
                control={Select}
                label="Product Manufacturer"
                options={manufacturers}
                placeholder="Product Manufacturer"
                name="product_manufacturer"
                onChange={handleChange}
              />

              <Form.Field>
                <label>Price</label>
                <input
                  placeholder="$0.00"
                  name="price"
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Packaging</label>
                <input placeholder="16/2" name="pkg" onChange={handleChange} />
              </Form.Field>
              <Form.Field>
                <label>Stock</label>
                <input placeholder="300" name="stock" onChange={handleChange} />
              </Form.Field>
              <Form.Field>
                <label>Stock Availability</label>
                <Dropdown
                  placeholder='Select Stock Availability'
                  fluid
                  search
                  selection
                  options={stockAvailabilityOptions}
                  onChange={handleChange}
                  name="availability"
                />
              </Form.Field>
              <Form.Field>
                <label>Available Date</label>
                <input type="date" name="available_date" onChange={handleChange} />
              </Form.Field>
              <Button primary size="huge" type="submit" onClick={handleSubmit}>
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Segment>
  );
};
export default ProductAdd;
