import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import {
  Segment,
  Button,
  Icon,
  CommentAction,
  Header,
  Table,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { APIBASEURL, CUSTOMSTYLES } from "../../config";

const CustomerDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [user, getUser] = useState([]);
  const [customer, getCustomer] = useState([]);
  const [orders, getOrders] = useState([]);
  const [address, getAddress] = useState([]);
  let i = 0;

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const getCustomerDetails = async () => {
    //console.log("useEffect called");
    setIsLoading(true);
    axios
      .get(`${APIBASEURL}users/${id}`)
      .then((response) => {
        const allCustomers = response.data;
        getUser(allCustomers);
        getCustomer(allCustomers.customers);
        getOrders(allCustomers.orders);
        getAddress(allCustomers.address);
        setIsLoading(false);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };
  const addressColumns = [
    {
      name: "type",
      selector: (row) => row.type,
    },
    {
      name: "street",
      selector: (row) => row.street,
    },
    {
      name: "apartment",
      selector: (row) => row.apartment,
    },
    {
      name: "city",
      selector: (row) => row.city,
    },
    {
      name: "state",
      selector: (row) => row.state,
    },
    {
      name: "zip",
      selector: (row) => row.zip,
    },
  ];
  const columns = [
    {
      name: "Order Date",
      selector: (row) => row.order_date,
      sortable: true,
    },
    {
      name: "total_price",
      selector: (row) => row.total_price,
    },
    {
      name: "discount_price",
      selector: (row) => row.discount_price,
    },
    {
      name: "subtotal",
      selector: (row) => row.subtotal,
    },
    {
      name: "salestax",
      selector: (row) => row.salestax,
    },
    {
      name: "grand_total",
      selector: (row) => row.grand_total,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button.Group icon>
            <Button as="a" href={`/admin/orders/${row.id}`}>
              <Icon name="eye" />
            </Button>
          </Button.Group>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      grow: "2",
    },
  ];
  if (user) {
    return (
      <Segment key={i++}>
        <Header as="h2" key={i++}>Customer Details</Header>
        <Table key={i++}>
          <Table.Body key={i++}>
            <Table.Row key={i++}>
              <Table.Cell>
                <Header as="h4">Name</Header>
              </Table.Cell>
              <Table.Cell>{user.name}</Table.Cell>
              <Table.Cell>
                <Header as="h4">Company Name</Header>
              </Table.Cell>
              <Table.Cell>{customer.company_name??''}</Table.Cell>
            </Table.Row>
            <Table.Row key={i++}>
              <Table.Cell>
                <Header as="h4">Email</Header>
              </Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>
                <Header as="h4">Primary Phone</Header>
              </Table.Cell>
              <Table.Cell>{customer.phone_primary??''}</Table.Cell>
            </Table.Row>
            <Table.Row key={i++}>
              <Table.Cell>
                <Header as="h4">Secondary Phone</Header>
              </Table.Cell>
              <Table.Cell>{customer.phone_secondary??''}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <DataTable
          title="Customer Orders"
          columns={columns}
          data={orders}
          pagination
          responsive
          fixedHeaderScrollHeight="100px"
          customStyles={CUSTOMSTYLES}
          key={i++}
        />
        <DataTable
          title="Customer Address"
          columns={addressColumns}
          data={address}
          pagination
          responsive
          fixedHeaderScrollHeight="100px"
          customStyles={CUSTOMSTYLES}
          key={i++}
        />
      </Segment>
    );
  }
  return null;
};

export default CustomerDetails;
