import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Segment,
  Header,
  Table,
  Grid,
  Image,
  Container,
  Button,
  Icon,
  Message,
  Label
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { APIBASEURL, currencyFormat } from "../../config";
import JsPDF from "jspdf";
import api from "../../api";
import { LOCAL_STORAGE_KEY_USER } from "../../config";
import { useNavigate, useLocation } from "react-router";

const OrderDetails = () => {
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [checkouts, setCheckouts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [address, setAddress] = useState([]);
  const [pickup_types, setPickupTypes] = useState([]);
  const [status, setStatus] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [inprogress, setInprogress] = useState(false);
  let i = 0;
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  console.log("user", user);
  useEffect(() => {
    const getOrderDetails = async () => {
      axios
        .get(`${APIBASEURL}orders/${id}`)
        .then((response) => {
          const allCustomers = response.data;
          // console.log('allCustomers.order_details :>> ', allCustomers.order_details);
          setOrders(allCustomers.order_details);
          setOrderItems(allCustomers.order_items);
          setCheckouts(allCustomers.checkouts);
          setCustomers(allCustomers.order_details.user);
          setAddress(allCustomers.checkouts.address);
          setPickupTypes(allCustomers.checkouts.pickup_types);
          switch(allCustomers?.order_details?.status){
            case 'pending':
              setStatusColor('yellow');
              break;
            case 'progress':
              setStatusColor('olive');
              break;
            case 'complete':
              setStatusColor('green');
              break;
            case 'cancelled':
              setStatusColor('red');
              break;
            default:
              setStatusColor('grey');
              break;
          } 
        })
        .catch((error) => console.log(`Error: ${error}`));
    };
    getOrderDetails();
  }, []);
  let navigate = useNavigate();
  const handleOrderUpdate = async (event, orderStatus) => {
    event.preventDefault();
    try {
      const response = await api.post("/orders-status-update", {
        order_id: id,
        order_status: orderStatus,
      });
      if(orderStatus !== 'delete')
        setStatus("Order status updated");
      else
        navigate("/admin/orders");
    } catch (error) {
      setStatus("Error updating order status");
    }
    window.location.reload();
  };

  if (orders) {
    let orderItemsList = orderItems.map((oi) => (
      <>
        <Table.Row key={i++}>
          <Table.Cell key={i++}>{oi.quantity}</Table.Cell>
          <Table.Cell key={i++}>{oi.part_number}</Table.Cell>
          <Table.Cell key={i++}>{oi.manufacturer_name}</Table.Cell>
          <Table.Cell key={i++}>{oi.name}</Table.Cell>
          <Table.Cell key={i++}>{oi.pkg}</Table.Cell>
          <Table.Cell key={i++}>{currencyFormat(oi.price)}</Table.Cell>
          <Table.Cell key={i++}>{currencyFormat(oi.subtotal)}</Table.Cell>
          <Table.Cell key={i++}>{currencyFormat(oi.subtotal)}</Table.Cell>
        </Table.Row>
      </>
    ));
    return (
      <>
        <Segment textAlign="center">
          <Button
            icon
            labelPosition="right"
            as="a"
            href={`/print-orders/${orders.id}`}
            target="_blank"
          >
            Print Preview
            <Icon name="right print" />
          </Button>
          {user.user_role == 1 ? (
            <>
              <Button
                icon
                labelPosition="right"
                as="a"
                onClick={async (event)=>{
                  setInprogress(true);
                  axios.get(`${APIBASEURL}send-invoice-email/${id}`).then((response)=>{
                    if(response.data.status === true){
                      setSuccess('Invoice sent to customer');
                    }else{
                      setError('Unable to send Customer Invoice!');
                    }
                    setInprogress(false);
                  }).catch((error) => {
                    setError('Unable to send Customer Invoice!');
                    setInprogress(false);
                  });
                }}
                color="green"
                disabled={inprogress}
                loading={inprogress}
              >
                Send Invoice
                <Icon name="right mail" />
              </Button>
              <Button
                icon
                labelPosition="right"
                as="a"
                href={`/admin/orders/edit/${orders.id}`}
                color="red"
              >
                Update Order
                <Icon name="right edit" />
              </Button>
              { (orders.status !== 'cancelled' &&  orders.status !== 'completed') &&
                <Button
                icon
                labelPosition="right"
                color="blue"
                onClick={(event) => handleOrderUpdate(event, "complete")}
                >
                Complete Order
                <Icon name="right shipping" />
              </Button>
            }
            {
              orders.status === 'cancelled'? 
                <Button
                icon
                labelPosition="right"
                color="olive"
                onClick={(event) => handleOrderUpdate(event, "pending")}
              >
                Restore Order
                <Icon name="right redo" />
              </Button> : 
                <Button
                icon
                labelPosition="right"
                color="orange"
                onClick={(event) => handleOrderUpdate(event, "cancelled")}
              >
                Cancel Order
                <Icon name="right cancel" />
              </Button>
            }
            {
            (orders.status === 'cancelled' || orders.status === 'pending') &&
              <Button
              icon
              labelPosition="right"
              color="black"
              onClick={(event) => handleOrderUpdate(event, "delete")}
            >
              Delete Order
              <Icon name="right trash" />
            </Button> 
          }
            </>
          ) : (
            orders.status === 'pending' && 
              <Button
              icon
              labelPosition="right"
              color="black"
              onClick={(event) => handleOrderUpdate(event, "cancelled")}
            >
              Cancel Order
              <Icon name="right cancel" />
            </Button>
          )}
          
          
        </Segment>
        <div>
          {success && <Message positive onDismiss={()=>{
            setSuccess("");
          }} 
            content={success} />}
          {error && <Message negative onDismiss={()=>{setError("")}} content={error} />}
        </div>
        <Header as="h2" key={i++} textAlign="center">
          Order Details
        </Header>
        <Container>
          {status ? (
            <Message positive>
              <Message.Header>{status}</Message.Header>
            </Message>
          ) : (
            ""
          )}
          <Segment key={i++} id="report">
            <Grid divided="vertically" key={i++}>
              <Grid.Row columns={2} key={i++}>
                <Grid.Column key={i++}>
                  <Image src="/images/logo.png" height="120" key={i++} />
                </Grid.Column>
                <Grid.Column textAlign="right" key={i++}>
                  <h2 key={i++}>Order#{orders.id}</h2>
                  <p key={i++}>Date: {orders.order_date}</p>
                  <p key={i++}>Status: <Label color={statusColor} key={statusColor} className="order-status">{orders.status}</Label></p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={3} key={i++}>
                <Grid.Column key={i++}>
                  <h4 key={i++}>Remit Payments To:</h4>
                  <p key={i++}>Munnerlyn Pyrotechnics</p>
                  <p key={i++}>Lexington SC 29072</p>
                </Grid.Column>

                <Grid.Column key={i++}>
                  <h4 key={i++}>Bill To:</h4>
                  <p key={i++}>{customers?.name}</p>
                  <p key={i++}>{address?.street}</p>
                  <p key={i++}>
                    {address?.city}, {address?.state} {address?.zip}
                  </p>
                </Grid.Column>

                <Grid.Column key={i++}>
                  <h4>{pickup_types.name}</h4>
                  <p>{pickup_types.pickup_by_name}</p>
                  <p>{pickup_types.event_name_Location}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Table key={i++}>
              <Table.Header key={i++}>
                <Table.Row key={i++}>
                  <Table.HeaderCell key={i++}>Qty</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Part Number</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Manufacturer</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Description</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Pkg</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>List Price</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Your Price</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Line Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body key={i++}>{orderItemsList}</Table.Body>
              <Table.Footer key={i++}>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    List Price Subtotal
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++}>
                    {currencyFormat(orders.total_price)}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    Discount
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++}>
                    {currencyFormat(orders.discount_price)}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    Subtotal
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++}>
                    {currencyFormat(orders.subtotal)}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    Applicable Sales Tax
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++}>
                    {currencyFormat(orders.salestax)}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={6} textAlign="center" key={i++}>
                    Our 3% cash discount will be removed for Credit or PayPal
                    payments
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={1} textAlign="right" key={i++}>
                    Total
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++}>
                    {currencyFormat(orders.grand_total)}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <h3 key={i++}>Order Note</h3>
            <p key={i++}>{checkouts?.order_note}</p>
          </Segment>
        </Container>
      </>
    );
  }
  return null;
};
export default OrderDetails;
