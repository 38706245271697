import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import api from "../api";
import CategoryFilter from "./CategoryFilter";
import ProductGrid from "./ProductGrid";
import Search from "./Search";

const Shop = () => {
  const { catid } = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [types, setTypes] = useState([]);
  useEffect(() => {
    getCategories();
    getAllProducts();
    getManufacturers();
    getTypes();
    return () => {
      console.log('This will be logged on unmount');
    };
  }, []);
  async function getCategories() {
    const parent = await api.get("categories");
    const parentData = parent.data;
    setCategories(parentData);
  }
  const getAllProducts = async (conditions = null, hideSold = false) => {
    let prodUrl = `products`;
    let params = '';
    // console.log("Conditions",conditions);
    if(conditions !== null){
        for(let x in conditions){
            if(conditions[x] !== "")
                params += '&' + x + '=' + conditions[x];
        }
    }
    if (catid !== undefined) {
      prodUrl = `products?cat_id=${catid}${params}`;
    }else{
        if(conditions)
            prodUrl = `products?${params.substring(1)}`;
    }
    // console.log('prodUrl',prodUrl);
    const response = await api.get(prodUrl);
    const allProducts = response?.data;
    // console.log("allProducts: ",allProducts);
    setProducts(allProducts);
  };
  const getManufacturers = async () => {
    const response = await api.get("manufacturers");
    const data = response.data;
    const mans = [];
    data.map((d) => {
      mans.push({
        key: d.id,
        text: d.name,
        value: d.id,
      });
    });
    setManufacturers(mans);
  };
  const getTypes = async () => {
    let mycat = 1;
    // console.log("mycat",mycat);
    // console.log("catid",catid);
    if(catid){
      mycat = catid;
    }
    // console.log("mycat",mycat);
    const response = await api.get(`types/${mycat}`);
    const data = response.data;
    const cols = [];
    data.map((d) => {
      cols.push({
        key: d.id,
        text: d.name,
        value: d.id,
      });
    });
    setTypes(cols);
  };

  const searchProductsHandler = (conditions,hideSold) => {
    // console.log("Inputs: ",conditions);
    getAllProducts(conditions);
  }
  return (
    <div>
      <Grid
        columns={2}
        divided
        style={{ margin: "1em 0em 0em", padding: "1em 0em" }}
      >
        <Grid.Row only='computer'>
          <Grid.Column width={3} className="ui left">
            <CategoryFilter categories={categories} catid={catid}/>
          </Grid.Column>
          <Grid.Column width={13}>
            <Search manufacturers={manufacturers} types={types} searchProductsHandler={searchProductsHandler}/>
            <ProductGrid products={products}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only='mobile'>
          <Grid.Column width={6} className="ui left">
            <CategoryFilter categories={categories} catid={catid}/>
          </Grid.Column>
          <Grid.Column width={10}>
            <Search manufacturers={manufacturers} types={types} searchProductsHandler={searchProductsHandler}/>
            <ProductGrid products={products}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default Shop;
