import React,{ Component, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
    Container,
    List,
    Image,
    Menu,
    Divider,
    Segment,
    Icon,
    Grid,
    Sidebar
} from 'semantic-ui-react'
import { LOCAL_STORAGE_KEY_USER } from "../config";
import { useNavigate } from "react-router-dom";

var i = 0;

function Backend() {
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
      );
    const navigate = useNavigate();
    useEffect(()=>{
        if (user && user.user_role != 1) {
            navigate('/user/login')
        }
    });
    return (
        <div>
            <Menu inverted>
                <Container>
                    
                    <Menu.Menu position='right'>
                        <Menu.Item name='login' as="a" href="/user/logout"> <Icon name='power' /> Logout</Menu.Item>
                    </Menu.Menu>
                </Container>
            </Menu>
            <Grid divided='vertically' key={i++}>
                <Grid columns={2} key={i++}>
                    <Grid.Column key={i++} width={2} className="ui left">
                        <Sidebar
                        as={Menu}
                        animation='overlay'
                        icon='labeled'
                        inverted
                        vertical
                        visible
                        width='thin'
                        >
                            <Menu.Item as='a' href="/admin/dashboard" header>
                                <Image size='tiny' src='/images/logo.png' style={{ marginRight: '1.5em' }} />
                            </Menu.Item>
                            <Menu.Item as='a' href="/admin/dashboard">
                                <Icon name='dashboard' />
                                Dashboard
                            </Menu.Item>
                            <Menu.Item as='a' href="/admin/customers">
                                <Icon name='users' />
                                Customers
                            </Menu.Item>
                            <Menu.Item as='a' href="/admin/orders">
                                <Icon name='cart' />
                                Orders
                            </Menu.Item>
                            <Menu.Item as='a' href="/admin/categories">
                                <Icon name='product hunt' />
                                Categories
                            </Menu.Item>
                            <Menu.Item as='a' href="/admin/products">
                                <Icon name='product hunt' />
                                Products
                            </Menu.Item>
                        </Sidebar>
                    </Grid.Column>
                    <Grid.Column key={i++} width={14}>
                        <Outlet />
                    </Grid.Column>
                </Grid>
            </Grid>
            <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '1em 0em' }}>
                <Container textAlign='center'>
                    <p>All content © Munnerlyn Pyrotechnics {(new Date().getFullYear())} | All rights reserved</p>
                </Container>
            </Segment>
        </div>
    );
}
export default Backend;