import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Grid, Radio, Input, Select,Message } from "semantic-ui-react";
import api from "../../api";
import CartSteps from "./CartSteps";
import {
  LOCAL_STORAGE_KEY_USER,
  LOCAL_STORAGE_KEY_CART_SUMMERY,
  LOCAL_STORAGE_KEY_CHECKOUT,
} from "../../config";
import { useNavigate, useLocation } from "react-router";

const Shipping = () => {
  var i = 0;
  const [shippingValue, setShippingValue] = useState(1);
  const [street, setStreet] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const handleShippingValueChange = (event, { value }) =>
    setShippingValue(value);
  const [pickupTypes, setPickupTypes] = useState([]);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  const [cartSummery, setCartSummery] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CART_SUMMERY)) ?? []
  );

  useEffect(() => {
    getPickupTypes();
    getUserAddress();
    getDeliverTo();
  }, []);
  async function getPickupTypes() {
    const pc = await api.get("pickuptypes");
    if ((pc.status = 200 && pc.data)) {
      let ptypes = pc.data.map((pd) => {
        return {
          key: pd.id,
          name: "shipingMethod",
          text: pd.name,
          value: pd.id,
        };
      });
      setPickupTypes(ptypes);
    }
  }

  const shippingMethods = pickupTypes.map((op) => (
    <Form.Field key={i++}>
      <Radio
        key={i++}
        label={op.text}
        name={op.name}
        value={op.value}
        checked={shippingValue == op.value}
        onChange={handleShippingValueChange}
      />
    </Form.Field>
  ));
  const [pickupByValue, setPickupByValue] = useState(localUser.user_id);
  const handlePickupByValueChange = (event, { value }) =>
    setPickupByValue(value);
  const pickupByOptions = [
    {
      key: "d",
      name: "pickupByRadio",
      text: localUser.user_name,
      value: localUser.user_id,
    },
    { key: "e", name: "pickupByRadio", text: "Someone Else", value: "0" },
  ];
  const pickupByRadio = pickupByOptions.map((op) => (
    <Form.Field key={i++}>
      <Radio
        key={i++}
        label={op.text}
        name={op.name}
        value={op.value}
        checked={pickupByValue == op.value}
        onChange={handlePickupByValueChange}
      />
    </Form.Field>
  ));

  const [deliverToOptions, setDeliverToOptions] = useState([]);
  const [deliverToValue, setDeliverToValue] = useState(1);
  const handleDeliverToValueChange = (event, { value }) =>
    setDeliverToValue(value);
  async function getDeliverTo() {
    const pc = await api.get("deliverylocationtypes");
    if ((pc.status = 200 && pc.data)) {
      let ptypes = pc.data.map((pd) => {
        return {
          key: pd.id,
          name: "deliverToRadio",
          text: pd.name,
          value: pd.id,
        };
      });
      setDeliverToOptions(ptypes);
    }
  }

  const deliverToRadio = deliverToOptions.map((op) => (
    <Form.Field key={i++}>
      <Radio
        key={i++}
        label={op.text}
        name={op.name}
        value={op.value}
        checked={deliverToValue == op.value}
        onChange={handleDeliverToValueChange}
      />
    </Form.Field>
  ));
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const handleAddressValueChange = (event, { value }) => setAddressValue(value);
  async function getUserAddress() {
    const pc = await api.get(`users/${localUser.user_id}`);
    if ((pc.status = 200 && pc.data.address)) {
      let paddress = pc.data.address.map((pd) => {
        return {
          key: pd.id,
          text: `${pd.street}, ${pd.city}, ${pd.state} ${pd.zip}`,
          value: pd.id,
        };
      });
      paddress.push({ key: "0", value: "0", text: "New Address" });
      setAddressOptions(paddress);
    }
  }
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveryDateErr, setDeliveryDateErr] = useState("");
  const handleDeliveryDate = (event, data) =>
    setDeliveryDate(event.target.value);
  let navigate = useNavigate();
  const handleShipping = (event) => {
    event.preventDefault();
    if (deliveryDate == "") {
      setDeliveryDateErr("Select Delivery Date");
    } else {
      let shippingDetails = {
        pickup_type_id: shippingValue,
        pickup_by_id: pickupByValue,
        pickup_by_name:
          pickupByValue == 0 ? someOneElseName : localUser.user_name,
        address_id: addressValue,
        delivery_location_type_id: deliverToValue,
        shipment_date: deliveryDate,
        address: {
          street: street,
          apartment: apartment,
          city: city,
          state: state,
          zip: zip,
        },
      };
      setCartSummery((values) => ({ ...values, shipping: shippingDetails }));
      localStorage.setItem(
        LOCAL_STORAGE_KEY_CHECKOUT,
        JSON.stringify({ shipping: shippingDetails })
      );
      navigate("/checkout/payment");
    }
  };
  const [someOneElseName, setSomeOneElseName] = useState(null);
  const handleSomeOneElse = (event) => setSomeOneElseName(event.target.value);

  return (
    <Grid textAlign="center">
      <Grid.Column style={{ maxWidth: 970, textAlign: "left" }}>
        <CartSteps mySteps="1" />
        <Form>
          <fieldset key={i++}>
            <legend>How do you want to take posession of this order?</legend>
            {shippingMethods}
          </fieldset>
          {shippingValue == 3 && (
            <fieldset>
              <legend>Tell us more about your delivery location</legend>
              <Grid divided="vertically" key={i++}>
                <Grid.Row columns={2} key={i++}>
                  <Grid.Column key={i++}>{deliverToRadio}</Grid.Column>
                  <Grid.Column key={i++}>
                    {deliverToValue == 1 && (
                      <Form.Field key={i++}>
                        <label>Club/Event Name and Location</label>
                        <Input key={i++} type="text" name="event_location" />
                      </Form.Field>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </fieldset>
          )}
          <fieldset key={i++}>
            <legend>
              {shippingValue == 1
                ? "Who will be picking up your order?"
                : "Who will we be shipping this to?"}
            </legend>
            {pickupByRadio}
            {pickupByValue == 0 && (
              <Form.Field key={i++} inline>
                <label>Contact Name</label>
                <Input
                  key={i++}
                  type="text"
                  name="someOneElse"
                  onChange={handleSomeOneElse}
                />
              </Form.Field>
            )}
            {shippingValue != 1 && (
              <Form.Field key={i++} inline>
                <label>Select an Address</label>
                <Select
                  placeholder="Select Address"
                  options={addressOptions}
                  onChange={handleAddressValueChange}
                />
              </Form.Field>
            )}
            {shippingValue != 1 && addressValue == 0 && (
              <Form.Field key={i++}>
                <label>Street Address</label>
                <Form.Input
                  fluid
                  placeholder="Street Address"
                  onChange={(event) => setStreet(event.target.value)}
                />
                <label>Apartment/Unit/Lot</label>
                <Form.Input
                  fluid
                  placeholder="Apartment/Unit/Lot"
                  onChange={(event) => setApartment(event.target.value)}
                />
                <label>City</label>
                <Form.Input
                  fluid
                  placeholder="City"
                  onChange={(event) => setCity(event.target.value)}
                />
                <label>State</label>
                <Form.Input
                  fluid
                  placeholder="State"
                  onChange={(event) => setState(event.target.value)}
                />
                <label>Zip Code</label>
                <Form.Input
                  fluid
                  placeholder="Zip Code"
                  onChange={(event) => setZip(event.target.value)}
                />
              </Form.Field>
            )}
          </fieldset>

          <fieldset>
            <legend>What date do you want to pick it up by?</legend>
            <Grid divided="vertically" key={i++}>
              <Grid.Row columns={2} key={i++}>
                <Grid.Column key={i++}>
                  <p key={i++}>
                    Note: We will do our best to accommodate your needs. Someone
                    from our office will contact you regarding our schedule.
                  </p>
                </Grid.Column>
                <Grid.Column key={i++}>
                  <input
                    key={i++}
                    type="date"
                    name="delivery_date"
                    onChange={handleDeliveryDate}
                    required
                  />
                  {deliveryDateErr == "" ? ("") : (<Message color="red">{deliveryDateErr}</Message>)}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </fieldset>
          <div>
            <Button floated="right" primary onClick={handleShipping} key={i++}>
              Next Step
            </Button>
            <Button floated="left" as="a" href="/" key={i++}>
              Go Back To: Shopping
            </Button>
          </div>
        </Form>
      </Grid.Column>
    </Grid>
  );
};
export default Shipping;
