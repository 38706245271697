import React from 'react'
import { Icon, Step } from 'semantic-ui-react'

const CartSteps = ({ mySteps }) => {
  return (
    <Step.Group>
      <Step active={mySteps == 1 ? true : false} disabled={mySteps >= 1 ? false : true}>
        <Icon name='truck' />
        <Step.Content>
          <Step.Title>Shipping</Step.Title>
          <Step.Description>Choose your shipping options</Step.Description>
        </Step.Content>
      </Step>

      <Step active={mySteps == 2 ? true : false} disabled={mySteps >= 2 ? false : true}>
        <Icon name='payment' />
        <Step.Content>
          <Step.Title>Billing</Step.Title>
          <Step.Description>Enter billing information</Step.Description>
        </Step.Content>
      </Step>

      <Step active={mySteps == 3 ? true : false} disabled={mySteps >= 3 ? false : true}>
        <Icon name='info' />
        <Step.Content>
          <Step.Title>Confirm Order</Step.Title>
        </Step.Content>
      </Step>

      <Step active={mySteps == 4 ? true : false} disabled={mySteps >= 4 ? false : true}>
        <Icon name='file text outline' />
        <Step.Content>
          <Step.Title> Order Summary</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  )
};

export default CartSteps
