import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Container
} from "semantic-ui-react";
import validator from "validator";
import api from "../api";
import { LOCAL_STORAGE_KEY_USER } from "../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const [isEmailCorrect, setEmailCorrect] = useState(false);
  const [isOtpVerified, setOtpVerified] = useState(false);
  const [isResetPassword, setResetPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otpError, setOtpError] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [repeatPasswordValue, setRepeatPasswordValue] = useState("");
  const [passwordError, setPasswordError] = useState("");
  
  const validateEmail = (email) => {
    if (emailValue && validator.isEmail(emailValue)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Enter valid Email!");
      return false;
    }
  };
  const validatePassword = ()  => {
    if (passwordValue !== repeatPasswordValue) {
      setPasswordError("Password and repeat password must match");
      return false;
    } else if (passwordValue.length < 5) {
      setPasswordError("Password must be higher than 5 character long");
      return false;
    }else {
      setPasswordError("");
      return true;
    }
  };
  async function checkEmail() {
    setLoading(true);
    validateEmail(emailValue);
    if(emailError === ""){
      const usr = await api.post("userauth/validate-email", {
        email: emailValue
      });
      if (usr.status === 200 && usr.data.status === true) {
        setEmailCorrect(true);
        setEmailError("");
        setLoading(false);
      }
      else{
        setEmailError("Invalid email id or user doesn't exist");
        setLoading(false);
      }
    }
    
  }
  async function verifyOTP() {
    setLoading(true);
    if(emailError === ""){
      const usr = await api.post("userauth/validate-otp", {
        email: emailValue,
        otp: otpValue
      });
      if (usr.status === 200 && usr.data.status === true) {
        setOtpVerified(true);
        setOtpError("");
        setLoading(false);
      }
      else{
        setOtpError("Invalid OTP");
        setLoading(false);
      }
    }
  }
  async function resetPassword() {
    setLoading(true);
    if(validatePassword() && passwordError === ""){
      const usr = await api.post("userauth/update-forgot-password", {
        email: emailValue,
        password: passwordValue
      });
      if (usr.status === 200 && usr.data.status === true) {
        setResetPassword(true);
        setPasswordError("");
        setLoading(false);
      }
      else{
        setPasswordError("Invalid Password");
        setLoading(false);
      }
    }
    else{
      setLoading(false);
    }
  }
  const verifyEmailForm = (
    <Form size="large">
      <Segment stacked>
        {emailError != "" ? <Message color="red">{emailError}</Message> : ""}
        <label>
          Email <span className="required">*</span>
        </label>
        <Form.Input
          fluid
          placeholder="E-mail address"
          type="email"
          id="userEmail"
          value={emailValue}
          onInput={(e) => setEmailValue(e.target.value)}
        />
        <Button loading={isLoading} color="blue" fluid size="large" onClick={checkEmail}>
          Reset Password
        </Button>
      </Segment>
    </Form>
  );
  
  const VerifyOtpForm =  (
    <Form size="large">
      <Segment stacked>
        {otpError != "" ? <Message color="red">{otpError}</Message> : ""}
        <label>
          OTP <span className="required">*</span>
        </label>
        <Form.Input
          fluid
          iconPosition="left"
          placeholder="OTP"
          type="text"
          id="otp"
          value={otpValue}
          onInput={(e) => setOtpValue(e.target.value)}
        />
        <Button loading={isLoading} color="blue" fluid size="large" onClick={verifyOTP}>
          Verify OTP
        </Button>
      </Segment>
    </Form>
  );
  
  const ResetPasswordForm = (
    <Form size="large">
      <Segment stacked>
        {passwordError != "" ? <Message color="red">{passwordError}</Message> : ""}
        <label>
          New Password <span className="required">*</span>
        </label>
        <Form.Input
          fluid
          type="password"
          id="newPassword"
          value={passwordValue}
          onInput={(e) => setPasswordValue(e.target.value)}
        />
        <label>
          Retype Password <span className="required">*</span>
        </label>
        <Form.Input
          fluid
          type="password"
          id="retypePassword"
          value={repeatPasswordValue}
          onInput={(e) => setRepeatPasswordValue(e.target.value)}
        />
        <Button loading={isLoading} color="blue" fluid size="large" onClick={resetPassword}>
          Reset Password
        </Button>
      </Segment>
    </Form>
  );
  const PasswordResetSuccess = (
    <h1>Password updated successfully</h1>
  );
  return (
    <Container>
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header
          as="h2"
          color="red"
          textAlign="center"
          style={{ marginTop: "40px" }}
        >
          Forgot Your Password?
        </Header>
        {!isEmailCorrect && verifyEmailForm}
        {(isEmailCorrect && !isOtpVerified) && VerifyOtpForm}
        {(isEmailCorrect && isOtpVerified && !isResetPassword) && ResetPasswordForm}
        {(isEmailCorrect && isOtpVerified && isResetPassword) && PasswordResetSuccess}
        <Message>
          Back to ? <a href={`/user/login`}>Login</a>
        </Message>
      </Grid.Column>
    </Grid>
    </Container>
  );
};
export default ForgotPassword;
