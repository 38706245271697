import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Segment, Button, Icon, Header, Label, Popup, Input } from "semantic-ui-react";
import { APIBASEURL, CUSTOMSTYLES, currencyFormat } from "../../config";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    getAllProducts();
  }, []);
  const getAllProducts = () => {
    axios
      .get(`${APIBASEURL}products`)
      .then((response) => {
        const allProducts = response.data;
        //console.log(allProducts);
        setProducts(allProducts);
        setFilteredData(allProducts);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };
  const handleFilter = (e) => {
    const searchValue = e.target.value;
    const filteredData = products.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.part_number.toLowerCase().includes(searchValue.toLowerCase()) || item.categories.name.toLowerCase().includes(searchValue.toLowerCase()) || item.manufacturers.name.toLowerCase().includes(searchValue.toLowerCase()) || item.availability.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  const columns = [
    {
      name: "SKU",
      selector: (row) => row?.part_number,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
      grow: 3,
    },
    {
      name: "Category Name",
      selector: (row) => row?.categories?.name,
      sortable: true,
    },
    {
      name: "Manufacturer",
      selector: (row) => row?.manufacturers?.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => currencyFormat(row?.price),
      sortable: false,
      center: true,
    },
    {
      name: "Sales Price",
      selector: (row) => currencyFormat(row?.sales_price),
      sortable: false,
      center: true,
    },
    {
      name: "Qty",
      selector: (row) => row?.stock,
      sortable: true,
      center: true,
    },
    {
      name: "Pkg",
      selector: (row) => row?.pkg,
      sortable: true,
    },
    {
      name: "Availability",
      selector: (row) =>
        row?.availability === "INSTOCK" ? (
          <Label color="green" key="green">
            INSTOCK
          </Label>
        ) : (
          <Popup
            content={`Arriving soon on or before ${row?.available_date}`}
            trigger={
              <Label color="red" key="red">
                SOLD
              </Label>
            }
          />
        ),
      sortable: true,
    },
    {
      name: "Video",
      cell: (row) =>
        row?.product_videos[0]?.video_url ? (
          <a href={`${row?.product_videos[0]?.video_url}`} target="_blank">
            <Icon name="video" />
          </a>
        ) : (
          <></>
        ),
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button.Group icon>
            <Button as="a" href={`/admin/products/edit/${row?.id}`}>
              <Icon name="pencil" />
            </Button>
            
          </Button.Group>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      grow: "2",
    },
  ];

  return (
    <Segment>
      <Segment clearing>
        <Header as="h2" floated="right">
          <Button as="a" href={`/admin/products/add`} color="red">
            <Icon name="plus" />
          </Button>
        </Header>
        <Header as="h2" floated="left">
          All Products
        </Header>
      </Segment>
      <Input fluid icon='search' placeholder='Search by SKU / Name / Category / Manufacturer / Availability' onChange={handleFilter} />
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        responsive
        fixedHeaderScrollHeight="100px"
        customStyles={CUSTOMSTYLES}
      />
    </Segment>
  );
};

export default ProductList;
