import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Container,
  List,
  Image,
  Menu,
  Divider,
  Segment,
  Icon,
  Grid,
  Sidebar,
} from "semantic-ui-react";
import { LOCAL_STORAGE_KEY_USER } from "../config";
import { useNavigate } from "react-router-dom";

function UserDashboard() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.user_role != 2) {
      navigate("/user/login");
    }
  });

  const [visible, setVisible] = useState(true);
  const toggleSidebar = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <div className="topMenu">
        <Menu inverted>
          <Container>
            <Menu.Menu position="right">
              <Menu.Item name="dashboard">
                <div className="hamburger" onClick={toggleSidebar}>
                  <div />
                  <div />
                  <div />
                </div>
              </Menu.Item>
              <Menu.Item name="dashboard" as="a" href="/user/dashboard">
                Welcome {user.user_name}
              </Menu.Item>
              <Menu.Item name="shop" as="a" href="/shop">
                Shop
              </Menu.Item>
              <Menu.Item name="cart" as="a" href="/cart">
                <Icon name="cart" /> Cart
              </Menu.Item>
              <Menu.Item name="login" as="a" href="/user/logout">
                <Icon name="power" /> Logout
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
      </div>

      <Grid>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            inverted
            vertical
            visible={visible}
            width='thin'
            className="leftMenu"
          >
            <Menu.Item as="a" href="/user/dashboard" header>
              <Image
                size="tiny"
                src="/images/logo.png"
                style={{ marginRight: "1.5em" }}
              />
            </Menu.Item>
            <Menu.Item as="a" href="/user/dashboard">
              <Icon name="dashboard" />
              Dashboard
            </Menu.Item>
            <Menu.Item as="a" href="/user/orders">
              <Icon name="address card" />
              Orders
            </Menu.Item>
            <Menu.Item as="a" href="/user/edit-account">
              <Icon name="pencil alternate" />
              Edit Account
            </Menu.Item>
          </Sidebar>
        <Grid.Column width={16}>
          <Container>
            <Outlet />
          </Container>
        </Grid.Column>
      </Grid>

      <Segment
        inverted
        vertical
        style={{ margin: "5em 0em 0em", padding: "1em 0em" }}
      >
        <Container textAlign="center">
          <p>
            All content © Munnerlyn Pyrotechnics {new Date().getFullYear()} |
            All rights reserved
          </p>
        </Container>
      </Segment>
    </div>
  );
}

export default UserDashboard;
