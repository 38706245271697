import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Segment, Button, Icon, Label, Popup } from "semantic-ui-react";
import { CUSTOMSTYLES, currencyFormat, LOCAL_STORAGE_KEY_CART } from "../config";
import { useNavigate } from "react-router";
import { format } from 'date-fns'

const ProductGrid = ({ products }) => {
  const [cartItems, setCartItems] = useState([]);
  const [myCart, setMyCart] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CART)) ?? []
  );

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(myCart));
  }, [myCart]);
  
  let navigate = useNavigate();
  const addToCartHandler = () => {
    if (cartItems.length > 0) {
      if (myCart.length > 0) {
        var existingCart = myCart;
        for (var i = 0, l = existingCart.length; i < l; i++) {
          for (var j = 0, ll = cartItems.length; j < ll; j++) {
            if (existingCart[i].id === cartItems[j].id) {
              existingCart.splice(i, 1, cartItems[j]);
              cartItems.pop(j);
              break;
            }
          }
        }
        // console.log("cartItems after POP",cartItems);
        if(cartItems.length > 0){
          for (let j = 0, ll = cartItems.length; j < ll; j++) {
            existingCart.push(cartItems[j]);
          }
        }
        // console.log("existingCart",existingCart);
        localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(existingCart));
        setMyCart(existingCart);
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(cartItems));
        setMyCart(cartItems);
      }
      navigate("/cart");
    } else {
      alert("Please add some qty of products");
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
  const handleQtyChange = (event) => {
    //alert(event.target.value);
    const pid = event.target.getAttribute("pid");
    const pvalue = event.target.value;
    let found = false;
    if (cartItems.length > 0) {
      cartItems.map((items, key) => {
        if (items.id == pid) {
          items.order_qty = pvalue;
          found = true;
        }
      });
    }
    if (!found) {
      var prod = products.filter((myProd) => {
        return myProd.id == pid;
      });
      prod[0].order_qty = pvalue;
      if(event.target.value > prod[0].stock){
        alert("Out of Stock");
        event.target.value = 0;
      }
      console.log("Prod",prod);
      
      setCartItems((cartItems) => [...cartItems, prod[0]]);
      console.log(pvalue);
      console.log(prod[0]);
    }
  };
  const columns = [
    {
      name: "Qty",
      cell: (row) => (
        <div className="ui input">
          <input
            type="number"
            name="qty[]"
            id={`qty_${row.id}`}
            pid={row.id}
            width={12}
            min="1" max={row.stock}
            onChange={handleQtyChange}
          />
        </div>
      ),
      center: true,
    },
    {
      name: "Availability",
      selector: (row) =>
        row?.stock > 0 ? (
          <Popup content={`Available Stock: ${row?.stock}`} trigger={<Label color="green" key="green">
          INSTOCK ({row?.stock})
        </Label>} />
        ) : (
          // <Popup content={`Arriving soon on or before ${row?.available_date}`} trigger={<Label color="red" key="red">
          //   SOLD
          // </Label>} />
          <>
          <Label color="red" key="red">
            SOLD
          </Label>
          {row?.available_date && !isNaN(Date.parse(row?.available_date))?<p>Arriving on/before <br/> {formatDate(row?.available_date)}</p>:""}
          </>
        ),
      sortable: false,
    },
    {
      name: "Part Number",
      selector: (row) => row?.part_number,
      sortable: true,
    },
    {
      name: "Manufacturer",
      selector: (row) => row?.manufacturers?.name,
      sortable: true,
    },
    {
      name: "Video",
      cell: (row) => 
        row?.product_videos[0]?.video_url ? (
          <a href={`${row?.product_videos[0]?.video_url}`} target="_blank">
            <Icon name="video" />
          </a>
      ) : (
        <></>
      ),
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row?.name,
      sortable: true,
      grow: 4,
    },
    {
      name: "Price",
      selector: (row) => currencyFormat(row?.price),
      sortable: true,
      center: true,
    },
    {
      name: "Pkg",
      selector: (row) => row?.pkg,
      sortable: true,
    },
  ];
  // console.log("myCart End", cartItems);
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };
  return (
    <Segment>
      <DataTable
        columns={columns}
        data={products}
        pagination
        responsive
        fixedHeaderScrollHeight="100px"
        customStyles={CUSTOMSTYLES}
        paginationPerPage={30}
        paginationComponentOptions={paginationComponentOptions}
        noRowsPerPage={true}
        className="productdatatable"
      />
      <>
      { products.length > 0 ? <Button color="blue" onClick={addToCartHandler}>Add to Cart</Button> : ''}
      </>
    </Segment>
  );
};
export default ProductGrid;

// To: mailto: rooterman.neks@gmail.com  
// CC: mailto:downingseptic@gmail.com 
// BCC: mailto: manager@palmettosoft.com 
