import React, {useEffect, useState} from "react"
import DataTable from 'react-data-table-component';
import { Segment, Button, Icon,Header } from 'semantic-ui-react';
import { CUSTOMSTYLES } from "../config";
import { LOCAL_STORAGE_KEY_USER } from "../config";
import api from "../api";

const Orders = ({title}) => {
    const [localUser, setLocalUser] = useState(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
      );
    const [orders, setOrders] = useState([]);
    useEffect(()=>{
        getAllOrders();
    },[]);
    const getAllOrders = async() => {
        if(localUser){
            const myorders = await api.get(`orders?userid=${localUser.user_id}`);
            console.log(myorders.data);
            setOrders(myorders.data);
        }
        
    }
    
   
    const columns = [
      {
        name: "Order Id",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Order Date",
        selector: (row) => row.order_date,
        sortable: true,
      },
      {
        name: "Order Status",
        selector: (row) => row.order_date
      },
      {
        name: "Amount",
        selector: (row) => row.grand_total,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div>
            <Button.Group icon>
              <Button as='a' href={`/user/orders/${row.id}`}>
                <Icon name="eye" />
              </Button>
            </Button.Group>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        grow: "2",
      },
    ];
    
    return (
        <>
        <Header as='h1'>{title}</Header>
        <Segment>
            <DataTable
                columns={columns}
                data={orders}
                pagination
                responsive
                fixedHeaderScrollHeight="100px"
                customStyles={CUSTOMSTYLES}
            />
        </Segment>
        </>
    );
}

export default Orders