const APIBASEURL = "https://shop.munnerlynpyro.com/api/v1/";
const LOCAL_STORAGE_KEY_CART = "munpyro_user_cart";
const LOCAL_STORAGE_KEY_USER = "munpyro_user";
const LOCAL_STORAGE_KEY_CART_SUMMERY = "munpyro_cart_summery";
const LOCAL_STORAGE_KEY_CHECKOUT = "munpyro_checkout";
const CUSTOMSTYLES = {
  tabble: {
    style: {
      width: "100%",
    },
  },

  headCells: {
    style: {
      paddingLeft: "5px", // override the cell padding for head cells
      paddingRight: "5px",
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};
function currencyFormat(num) {
  if (num > 0) {
    return "$" + Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "$0.00";
  }
}
function weightFormat(num) {
  if (num > 0) {
    return Number(num).toFixed(2);
  } else {
    return "0.00";
  }
}
export {
  APIBASEURL,
  CUSTOMSTYLES,
  currencyFormat,
  weightFormat,
  LOCAL_STORAGE_KEY_CART,
  LOCAL_STORAGE_KEY_USER,
  LOCAL_STORAGE_KEY_CART_SUMMERY,
  LOCAL_STORAGE_KEY_CHECKOUT,
};
