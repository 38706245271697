import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Container
} from "semantic-ui-react";
import api from "../api";
import validator from "validator";
import { LOCAL_STORAGE_KEY_USER } from "../config";
import { useNavigate, useSearchParams } from "react-router-dom";

const Signup = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [validError, setValidError] = useState({
    name: "",
    email: "",
    company_name: "",
    phone_primary: "",
    phone_secondary: "",
    password: "",
    contact_name: "",
    street: "",
    apartment: "",
    city: "",
    state: "",
    zip: "",
  });
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    company_name: "",
    phone_primary: "",
    phone_secondary: "",
    password: "",
    contact_name: "",
    street: "",
    apartment: "",
    city: "",
    state: "",
    zip: "",
  });
  const [success, setSuccess] = useState("");
  const handleChange = (event, data) => {
    if (data) {
      const name = data.name;
      const value = data.value;
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    console.log("inputs", JSON.stringify(inputs));
    let i = 0;
    Object.keys(inputs).map(async (key) => {
      console.log("key " + i + ":", key);
      switch (key) {
        case "name":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({
                ...values,
                [key]: "Full Legal Name is required",
              }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "email":
          if (
            !validator.isEmail(inputs[key]) ||
            validator.isEmpty(inputs[key])
          ) {
            setValidError((values) => ({
              ...values,
              [key]: "Invalid email address",
            }));
          } else {
            const validationResponse = await api.post(
              "userauth/validate-email",
              { email: inputs[key] }
            );
            console.log("validationResponse", validationResponse);
            if (
              validationResponse.status == 200 &&
              validationResponse.data.status
            ) {
              setValidError((values) => ({
                ...values,
                [key]: "Email address is already taken.",
              }));
            } else {
              setValidError((values) => ({ ...values, [key]: "" }));
            }
          }
          break;
        case "phone_primary":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({
                ...values,
                [key]: "Invalid phone number",
              }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "password":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({
                ...values,
                [key]: "Invalid password",
              }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "street":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({
                ...values,
                [key]: "Invalid street address",
              }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "city":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({ ...values, [key]: "Invalid city" }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "state":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({ ...values, [key]: "Invalid state" }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        case "zip":
          validator.isEmpty(inputs[key])
            ? setValidError((values) => ({ ...values, [key]: "Invalid zip" }))
            : setValidError((values) => ({ ...values, [key]: "" }));
          break;
        default:
          break;
      }
      i++;
    });
    if (
      validError.name === "" &&
      validError.email === "" &&
      validError.street === "" &&
      validError.phone_primary === "" &&
      validError.password === "" &&
      validError.city === "" &&
      validError.state === "" &&
      validError.zip === ""
    ) {
      Object.keys(inputs).map((key) => {
        formData.append(key, inputs[key]);
      });
      const response = await api.post("userauth/signup", formData);
      console.log("response", response.data);
      if (response.status === 200 && response.data.status === true) {
        setSuccess(response.data.msg);
        setError("");
        let userData = {
          isLoggedin: true,
          user_id: response?.data?.user?.id,
          user_role: response?.data?.user?.role_id,
          user_name: response?.data?.user?.name,
          user_email: response?.data?.user?.email,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY_USER, JSON.stringify(userData));
        if (redirectTo) {
          navigate(redirectTo);
        } else {
          if (userData.user_role == 1) {
            navigate("/admin/dashboard");
          }
          if (userData.user_role == 2) {
            navigate("/user/dashboard");
          }
        }
      } else {
        setError("Invalid input, failed to create user account");
        setSuccess("");
      }
    }
  };
  console.log("error", error);
  console.log("inputs", inputs);
  console.log("success", success);
  console.log("validError", validError);
  return (
    <Container>
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h2" color="red" textAlign="center">
          Create an account
        </Header>
        {error != "" ? (
          <Message negative>
            <Message.Header>{error}</Message.Header>
          </Message>
        ) : (
          ""
        )}
        {success != "" ? (
          <Message positive>
            <Message.Header>{success}</Message.Header>
          </Message>
        ) : (
          
        <Form size="large" inline>
          <Segment textAlign="left">
            <fieldset>
              <legend>Basic Information</legend>
              <label>
                Full Legal Name <span className="required">*</span>
              </label>
              <Form.Input
                placeholder="Full Legal Name"
                name="name"
                onChange={handleChange}
              />
              {validError.name !== "" ? (
                <Message color="red">{validError.name}</Message>
              ) : (
                ""
              )}
              <label>
                Email <span className="required">*</span>
              </label>
              <Form.Input
                fluid
                placeholder="E-mail address"
                name="email"
                onChange={handleChange}
              />
              {validError.email != "" ? (
                <Message color="red">{validError.email}</Message>
              ) : (
                ""
              )}
              <label>Company</label>
              <Form.Input
                fluid
                placeholder="Company Name"
                name="company_name"
                onChange={handleChange}
              />
              <label>
                Primary Phone <span className="required">*</span>
              </label>
              <Form.Input
                fluid
                placeholder="Primary Phone"
                name="phone_primary"
                onChange={handleChange}
              />
              {validError.phone_primary != "" ? (
                <Message color="red">{validError.phone_primary}</Message>
              ) : (
                ""
              )}
              <label>Secondary Phone</label>
              <Form.Input
                fluid
                placeholder="Secondary Phone"
                name="phone_secondary"
                onChange={handleChange}
              />
              <label>
                Password <span className="required">*</span>
              </label>
              <Form.Input
                fluid
                placeholder="Password"
                type="password"
                name="password"
                onChange={handleChange}
              />
              {validError.password != "" ? (
                <Message color="red">{validError.password}</Message>
              ) : (
                ""
              )}
            </fieldset>
            <fieldset>
              <legend>Billing Address</legend>
              <label>Contact Name</label>
              <Form.Input
                fluid
                placeholder="Contact Name"
                name="contact_name"
                onChange={handleChange}
              />
              <label>
                Street Address <span className="required">*</span>
              </label>
              <Form.Input
                fluid
                placeholder="Street Address"
                name="street"
                onChange={handleChange}
              />
              {validError.street != "" ? (
                <Message color="red">{validError.street}</Message>
              ) : (
                ""
              )}
              <label>Apartment/Unit/Lot</label>
              <Form.Input
                fluid
                placeholder="Apartment/Unit/Lot"
                name="apartment"
                onChange={handleChange}
              />
              <label>
                City <span className="required">*</span>
              </label>
              <Form.Input
                fluid
                placeholder="City"
                name="city"
                onChange={handleChange}
              />
              {validError.city != "" ? (
                <Message color="red">{validError.city}</Message>
              ) : (
                ""
              )}
              <label>
                State <span className="required">*</span>
              </label>
              <Form.Input
                fluid
                placeholder="State"
                name="state"
                onChange={handleChange}
              />
              {validError.state != "" ? (
                <Message color="red">{validError.state}</Message>
              ) : (
                ""
              )}
              <label>
                Zip Code <span className="required">*</span>
              </label>
              <Form.Input
                fluid
                placeholder="Zip Code"
                name="zip"
                onChange={handleChange}
              />
              {validError.zip != "" ? (
                <Message color="red">{validError.zip}</Message>
              ) : (
                ""
              )}
            </fieldset>
            <Button color="blue" fluid size="large" onClick={handleSubmit}>
              Signup
            </Button>
          </Segment>
        </Form>

        )}
        <Message>
          Already have an account? <a href="/user/login">Login</a>
        </Message>
      </Grid.Column>
    </Grid>
    </Container>
  );
};

export default Signup;
