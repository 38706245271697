import React, { useEffect, useState } from "react";
import {
  Segment,
  Button,
  Icon,
  Header,
  Form,
  Message,
  Grid,
  Select,
  Dropdown,
} from "semantic-ui-react";
import api from "../../api";
import { useNavigate, useLocation } from "react-router";
import { useParams } from "react-router-dom";

const ProductEdit = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  const [validationErrors, setvalidationErrors] = useState([]);
  const [inputs, setInputs] = useState({});
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    part_number: '',
    description: '',
    product_category: 0,
    product_manufacturer: 0,
    pkg: '',
    stock: '',
    product_video_id: 0,
    product_video_url: '',
    availability: '',
    available_date: '',
  });
  const stockAvailabilityOptions = [
    { key: 'SOLD', value: 'SOLD', text: 'SOLD' },
    { key: 'INSTOCK', value: 'INSTOCK', text: 'INSTOCK' },
  ];
  
  useEffect(() => {
    getProduct();
    getCategories();
    getManufacturers();
  }, []);

  const getProduct = async () => {
    let _product_video_id = 0;
    let _product_video_url = "";
    const response = await api.get("products/"+id);
    const data = response.data;
    // console.log("products:", data);
    if(response.status === 200 && response.data){
      if(data.product_videos.length > 0){
        let pvideo = data.product_videos[0];
        _product_video_id = pvideo.id || 0;
        _product_video_url = pvideo.video_url || "";
      }
      setFormData({
        id: data.id || null,
        name: data.name || "",
        part_number: data.part_number || "",
        description: data.description || "",
        product_category: data.category_id || 0,
        product_manufacturer: data.manufacturer_id || 0,
        pkg: data.pkg || 0,
        stock: data.stock || 0,
        price: data.price || 0,
        product_video_id: _product_video_id,
        product_video_url: _product_video_url,
        availability: data.availability || "SOLD",
        available_date: data.available_date || "",
      });
      
    }
    
    
  };
  const getCategories = async () => {
    const response = await api.get("categories");
    const data = response.data;
    //console.log("Categories:", data);
    let cats = [];
    //setCategories(data);
    data.map((d) => {
      cats.push({
        key: d.id,
        text: d.name,
        value: d.id,
      });
      if (d.child && d.child.length) {
        let childcats = d.child;
        childcats.map((cd) => {
          cats.push({
            key: ""+cd.id,
            text: "    " + cd.name,
            value: ""+cd.id,
          });
          if (cd.child && cd.child.length) {
            let chilcChildCats = cd.child;
            chilcChildCats
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((ccd) => {
                cats.push({
                  key: ""+ccd.id,
                  text: "        " + ccd.name,
                  value: ""+ccd.id,
                });
              });
          }
        });
      }
    });
    setCategories(cats);
  };
  const getManufacturers = async () => {
    const response = await api.get("manufacturers");
    const data = response.data;
    const mans = [];
    data.map((d) => {
      mans.push({
        key: ""+d.id,
        text: ""+d.name,
        value: ""+d.id,
      });
    });
    setManufacturers(mans);
  };

  const handleChange = (event, data) => {
    if (data) {
      const name = data.name;
      const value = data.value;
      setFormData((values) => ({ ...values, [name]: value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setFormData((values) => ({ ...values, [name]: value }));
    }
  };
  let navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const fd = new FormData();
    // console.log("formData", JSON.stringify(formData));
    //formData.append("inputs", JSON.stringify(inputs));
    Object.keys(formData).map((key) => {
      fd.append(key, formData[key]);
    });
    // console.log("FD",fd);
    try {
      const response = await api.post("products", fd);
      // console.log(response);
      if (response.status === 200 && response.data.status === true) {
        setSuccess("Product Addded Successfully");
        navigate("/admin/products");
      } else {
        setvalidationErrors(response.data.errors);
        setError("Invalid Input, Failed to Add Product");
      }
    } catch (error) {
      // console.log("Error", error.message);
      setError("Invalid Input, Failed to Add Product");
    }
  };
  return (
    <Segment>
      <h2>Add New Product</h2>
      {success ?? (
        <Message positive>
          <Message.Header>{success}</Message.Header>
        </Message>
      )}
      {error != "" ? (
          <Message negative>
            <Message.Header>{error}</Message.Header>
          </Message>
        ) : (
          ""
        )}
      <Form>
        <Grid celled="internally">
          <Grid.Row>
            <Grid.Column width={11}>
              <Form.Field>
                <label>Product Name</label>
                <input
                  placeholder="Product Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <input type="hidden" name="id" value={formData.id}/>
              </Form.Field>
              <Form.Field>
                <label>SKU / Part Number</label>
                <input
                  placeholder="SKU / Part Number"
                  name="part_number"
                  value={formData.part_number}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Product Description</label>
                <textarea
                  placeholder="Product Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
              </Form.Field>
              <Form.Field>
                <label>Video URL (YouTube URL)</label>
                <input
                  placeholder="https://www.youtube.com/watch?v=Au63DyjBQ7k"
                  name="video_url"
                  value={formData.product_video_url}
                  onChange={handleChange}
                />
                <input type="hidden" name="product_video_id" value={formData.product_video_id}/>
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={5}>
              {/* <Form.Field
                control={Select}
                label="Product Category"
                options={categories}
                placeholder="Product Category"
                name="product_category"
                onChange={handleChange}
                value={formData.category_id}
              /> */}
              <Form.Field>
                <label>Product Category</label>
                <Dropdown
                    placeholder='Product Category'
                    fluid
                    search
                    selection
                    options={categories}
                    onChange={handleChange}
                    name="product_category"
                    value={formData.product_category}
                  />
                </Form.Field>
              <Form.Field
                control={Select}
                label="Product Manufacturer"
                options={manufacturers}
                placeholder="Product Manufacturer"
                name="product_manufacturer"
                onChange={handleChange}
                value={formData.product_manufacturer}
              />
              <Form.Field>
                <label>Price</label>
                <input
                  placeholder="$0.00"
                  name="price"
                  onChange={handleChange}
                  value={formData.price}
                />
              </Form.Field>
              <Form.Field>
                <label>Packaging</label>
                <input placeholder="16/2" name="pkg" onChange={handleChange} value={formData.pkg}/>
              </Form.Field>
              <Form.Field>
                <label>Stock</label>
                <input placeholder="300" name="stock" onChange={handleChange} value={formData.stock}/>
              </Form.Field>
              <Form.Field>
                <label>Stock Availability</label>
                <Dropdown
                  placeholder='Select Stock Availability'
                  fluid
                  search
                  selection
                  options={stockAvailabilityOptions}
                  onChange={handleChange}
                  name="availability"
                  value={formData.availability}
                />
              </Form.Field>
              <Form.Field>
                <label>Available Date</label>
                <input type="date" name="available_date" onChange={handleChange} value={formData.available_date}/>
              </Form.Field>
              <Button primary size="huge" type="submit" onClick={handleSubmit}>
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Segment>
  );
};
export default ProductEdit;
