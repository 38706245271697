import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Segment,
  Header,
  Table,
  Grid,
  Image,
  Container,
  Button,
  Icon,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { APIBASEURL, currencyFormat } from "../../config";

const PrintOrders = () => {
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [checkouts, setCheckouts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [address, setAddress] = useState([]);
  const [pickup_types, setPickupTypes] = useState([]);
  let i = 0;

  useEffect(() => {
    const getOrderDetails = async () => {
      axios
        .get(`${APIBASEURL}orders/${id}`)
        .then((response) => {
          const allCustomers = response.data;
          setOrders(allCustomers.order_details);
          setOrderItems(allCustomers.order_items);
          setCheckouts(allCustomers.checkouts);
          setCustomers(allCustomers.order_details.user);
          setAddress(allCustomers.checkouts.address);
          setPickupTypes(allCustomers.checkouts.pickup_types);
        })
        .catch((error) => console.log(`Error: ${error}`));
    };
    getOrderDetails();
  }, []);

  if (orders) {
    let orderItemsList = orderItems.map((oi) => (
      <>
        <tr key={i++}>
          <td key={i++}>{oi.quantity}</td>
          <td key={i++}>{oi.part_number}</td>
          <td key={i++}>{oi.manufacturer_name}</td>
          <td key={i++}>{oi.name}</td>
          <td key={i++}>{oi.pkg}</td>
          <td key={i++}>{currencyFormat(oi.price)}</td>
          <td key={i++}>{currencyFormat(oi.subtotal)}</td>
        </tr>
      </>
    ));
    return (
      <>
        <Segment textAlign="center">
          <Button icon labelPosition="right" onClick={() => window.print()} primary>
            Print Order
            <Icon name="left print" />
          </Button>
        </Segment>
        <Container id="componentToPrint">
          <Segment key={i++} id="report">
            <Grid divided="vertically" key={i++}>
              <Grid.Row columns={2} key={i++}>
                <Grid.Column key={i++}>
                  <Image src="/images/logo.png" height="120" key={i++} />
                </Grid.Column>
                <Grid.Column textAlign="right" key={i++}>
                  <h2 key={i++}>Order#{orders.id}</h2>
                  <p key={i++}>Date: {orders.order_date}</p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={3} key={i++}>
                <Grid.Column key={i++}>
                  <h4 key={i++}>Remit Payments To:</h4>
                  <p key={i++}>Munnerlyn Pyrotechnics</p>
                  <p key={i++}>Lexington SC 29072</p>
                </Grid.Column>

                <Grid.Column key={i++}>
                  <h4 key={i++}>Bill To:</h4>
                  <p key={i++}>{customers?.name}</p>
                  <p key={i++}>{address?.street}</p>
                  <p key={i++}>
                    {address?.city}, {address?.state} {address?.zip}
                  </p>
                </Grid.Column>

                <Grid.Column key={i++}>
                  <h4>{pickup_types.name}</h4>
                  <p>{pickup_types.pickup_by_name}</p>
                  <p>{pickup_types.event_name_Location}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <table key={i++} className="ui table">
              <thead key={i++}>
                <tr key={i++}>
                  <th key={i++}>Qty</th>
                  <th key={i++}>Part Number</th>
                  <th key={i++}>Manufacturer</th>
                  <th key={i++}>Description</th>
                  <th key={i++}>Pkg</th>
                  <th key={i++}>Price</th>
                  <th key={i++}>Line Total</th>
                </tr>
              </thead>
              <tbody key={i++}>{orderItemsList}
                <tr key={i++}>
                  <td colSpan={6} className="right aligned" key={i++}>
                    List Price Subtotal
                  </td>
                  <td key={i++}>
                    {currencyFormat(orders.total_price)}
                  </td>
                </tr>
                <tr key={i++}>
                  <td colSpan={6} className="right aligned" key={i++}>
                    Discount
                  </td>
                  <td key={i++}>
                    {currencyFormat(orders.discount_price)}
                  </td>
                </tr>
                <tr key={i++}>
                  <td colSpan={6} className="right aligned" key={i++}>
                    Subtotal
                  </td>
                  <td key={i++}>
                    {currencyFormat(orders.subtotal)}
                  </td>
                </tr>
                <tr key={i++}>
                  <td colSpan={6} className="right aligned" key={i++}>
                    Applicable Sales Tax
                  </td>
                  <td key={i++}>
                    {currencyFormat(orders.salestax)}
                  </td>
                </tr>
                <tr key={i++}>
                  <td colSpan={5} textAlign="center" key={i++}>
                    Our 3% cash discount will be removed for Credit or PayPal
                    payments
                  </td>
                  <td colSpan={1} className="right aligned" key={i++}>
                    Total
                  </td>
                  <td key={i++}>
                    {currencyFormat(orders.grand_total)}
                  </td>
                </tr>
                </tbody>
            </table>
            <h3 key={i++}>Order Note</h3>
            <p key={i++}>{checkouts?.order_note}</p>
          </Segment>
        </Container>
      </>
    );
  }
  return null;
};

export default PrintOrders;
