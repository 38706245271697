import React, { Component, useState, useEffect } from "react";
import { Segment, Form, Grid } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const Search = ({ manufacturers, types, searchProductsHandler }) => {
  const [hideSold, setHideSold] = useState(false);
  // console.log("hideSold",hideSold);
  const [inputs, setInputs] = useState({
    search_partnumber: "",
    search_manufacturer: "",
    search_types: "",
    // hide_sold: false,
  });
  const handleHideSold = (event) => {
    // let isChecked = event.target.checked;
    // console.log("isChecked",isChecked);
    // console.log("value",event.target.value);
    // setHideSold((hideSold) => isChecked);
    setHideSold(!hideSold);
    setInputs((values) => ({ ...values, ["hide_sold"]: !hideSold }));
    console.log("hideSold",hideSold);
  };
  let navigate = useNavigate();
  const clearSearch = () => {
    // setInputs({
    //   search_partnumber: "",
    //   search_manufacturer: "",
    //   search_types: "",
    //   hide_sold: false,
    // });
    let path = `/shop`;
    navigate(path);
  };
  const handleSearchChange = (event, data) => {
    if (data) {
      const name = data.name;
      const value = data.value;
      setInputs((values) => ({ ...values, [name]: value }));
    } else {
      // console.log("Event", event);
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (
      inputs.search_partnumber == "" &&
      inputs.search_manufacturer == "" &&
      inputs.search_types == "" &&
      hideSold === false
    ) {
      alert("Please fill the search criteria!!!");
      return;
    }
    //inputs.push(hideSold);
    console.log("search_inputs", inputs);
    searchProductsHandler(inputs);
  };
  return (
    <div>
      <h1>Shop</h1>
      <p>Search products...</p>
      <Segment inverted color="yellow">
        <Form inverted onSubmit={handleSearchSubmit}>
          <Form.Group>
            <Form.Input
              width={10}
              fluid
              label="Part Number or Description"
              name="search_partnumber"
              onChange={handleSearchChange}
            />
            <Form.Select
              width={6}
              fluid
              label="Manufacturer"
              options={manufacturers}
              placeholder="Manufacturer"
              name="search_manufacturer"
              onChange={handleSearchChange}
            />
            <Form.Select
              width={4}
              fluid
              label="Type"
              options={types}
              placeholder="Types"
              name="search_types"
              onChange={handleSearchChange}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Checkbox
              width={13}
              label='Hide "SOLD" products.'
              checked={hideSold}
              onChange={handleHideSold}
              name="hide_sold"
              className="hidesold"
            />
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Form.Button color="blue">Search</Form.Button>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form.Button color="black" onClick={clearSearch}>
                    Show All
                  </Form.Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      <p>
        Our online ordering system is automatically updated every 5 minutes to
        reflect our current inventory. So you'll always know if we have what you
        need. No guesses or surprise substitutions. We Guarantee It!
      </p>
    </div>
  );
};
export default Search;
