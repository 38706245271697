import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Radio,
  Image,
  Table,
} from "semantic-ui-react";
import CartSteps from "./CartSteps";
import api from "../../api";
import {
  LOCAL_STORAGE_KEY_USER,
  LOCAL_STORAGE_KEY_CART_SUMMERY,
  LOCAL_STORAGE_KEY_CHECKOUT,
} from "../../config";
import { useNavigate, useLocation } from "react-router";

const Payment = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [checkoutData, setCheckoutData] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CHECKOUT)) ?? []
  );
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  useEffect(() => {
    getUserAddress();
  }, []);
  async function getUserAddress() {
    const pc = await api.get(`users/${localUser.user_id}`);
    if ((pc.status = 200 && pc.data)) {
      setUserDetails(pc.data);
      setUserAddress(pc.data.address);
    }
  }

  var i = 0;
  const [pmName, setpmName] = useState("");
  const [paymentMethodValue, setPaymentMethodValue] = useState(0);
  const handlePaymentMethodValueChange = (event, { value }) =>{
    setPaymentMethodValue(value);
    if(value==1){
        setpmName("Cash");
    }else if(value == 2){
        setpmName("Bank Check");
    }else if(value == 3){
        setpmName("Credit Card");
    }else if(value == 4){
        setpmName("PayPal");
    }
  }

  let navigate = useNavigate();
  const handlePayment = (event) => {
    event.preventDefault();

    if (paymentMethodValue > 0) {
      
      let x = {
        shipping: checkoutData.shipping,
        payment_method: paymentMethodValue,
        payment_method_name: pmName,
      };
      setCheckoutData(x);
      localStorage.setItem(LOCAL_STORAGE_KEY_CHECKOUT, JSON.stringify(x));
      navigate("/checkout/confirm");
    } else {
      alert("Select a payment method to continue");
    }
  };
  return (
    <Grid textAlign="center">
      <Grid.Column style={{ maxWidth: 970, textAlign: "left" }}>
        <CartSteps mySteps="2" />
        <Form>
          <fieldset key={i++}>
            <legend>Confirm your billing information</legend>
            <Table compact className="noborder">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Acccount Name</Table.Cell>
                  <Table.Cell colSpan="2">{userDetails.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Billing Address</Table.Cell>
                  <Table.Cell>{`${userAddress[0]?.street}, ${userAddress[0]?.city}, ${userAddress[0]?.state} ${userAddress[0]?.zip}`}</Table.Cell>
                  <Table.Cell rowSpan="3" textAlign="right">
                    <Checkbox label="My billing information is correct." />
                    <p style={{ fontWeight: "bold" }}>
                      You must check box to confirm.
                    </p>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Email</Table.Cell>
                  <Table.Cell>{userDetails.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Primary Phone</Table.Cell>
                  <Table.Cell>
                    {userDetails?.customers?.phone_primary}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </fieldset>

          <fieldset key={i++}>
            <legend>How will you be paying for this order?</legend>
            <Grid divided="vertically" key={i++}>
              <Grid.Row columns={2} key={i++}>
                <Grid.Column key={i++}>
                  <Form.Field key={i++}>
                    <Radio
                      key={i++}
                      label={{
                        children: (
                          <Image src="/images/cash-icon.png" size="tiny" />
                        ),
                      }}
                      name="payment_method"
                      value="1"
                      pname="Cash"
                      checked={paymentMethodValue == 1}
                      onChange={handlePaymentMethodValueChange}
                    ></Radio>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column key={i++}>
                  <Form.Field key={i++}>
                    <Radio
                      key={i++}
                      label={{
                        children: (
                          <Image src="/images/check-icon.png" size="tiny" />
                        ),
                      }}
                      name="payment_method"
                      value="2"
                      pname="Bank Check"
                      checked={paymentMethodValue == 2}
                      onChange={handlePaymentMethodValueChange}
                    ></Radio>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header as="h5">
              Our 3% cash discount will be removed for Credit or PayPal payments
            </Header>
            <Grid divided="vertically" key={i++}>
              <Grid.Row columns={2} key={i++}>
                <Grid.Column key={i++}>
                  <Form.Field key={i++}>
                    <Radio
                      key={i++}
                      label={{
                        children: (
                          <Image
                            src="/images/credit-card-icon.jpg"
                            size="tiny"
                          />
                        ),
                      }}
                      name="payment_method"
                      value="3"
                      pname="Credit Card"
                      checked={paymentMethodValue == 3}
                      onChange={handlePaymentMethodValueChange}
                    ></Radio>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column key={i++}>
                  <Form.Field key={i++}>
                    <Radio
                      key={i++}
                      label={{
                        children: (
                          <Image src="/images/paypal-icon.png" size="tiny" />
                        ),
                      }}
                      name="payment_method"
                      value="4"
                      pname="PayPal"
                      checked={paymentMethodValue == 4}
                      onChange={handlePaymentMethodValueChange}
                    ></Radio>
                  </Form.Field>
                </Grid.Column>
                <p style={{ fontStyle: "italic" }}>
                  We do not process your payment online. Specific payment
                  instructions will be displayed in Step 4: Confirmation & Order
                  Summary, as well as in your e-mail confirmation message.
                </p>
              </Grid.Row>
            </Grid>
          </fieldset>
          <div>
            <Button floated="right" primary onClick={handlePayment} key={i++}>
              Next Step
            </Button>
            <Button floated="left" as="a" href="/checkout/shipping" key={i++}>
              Go Back To: Shipping
            </Button>
          </div>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default Payment;
