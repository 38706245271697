import React, { useEffect, useState } from "react";
import CategoryFilter from "./CategoryFilter";
import { Table, Form, Button, Grid,Message } from "semantic-ui-react";
import {
  currencyFormat,
  weightFormat,
  LOCAL_STORAGE_KEY_CART,
  LOCAL_STORAGE_KEY_USER,
  LOCAL_STORAGE_KEY_CART_SUMMERY,
} from "../config";
import api from "../api";
import { useNavigate, useLocation } from "react-router";

const Cart = () => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CART)) ?? []
  );
  const [cartSummery, setCartSummery] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CART_SUMMERY)) ?? []
  );
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER)) ?? []
  );
  const [categories, setCategories] = useState([]);
  const [annualpurchase, setAnnualpurchase] = useState(0);
  const [cartUpdateMsg, setCartUpdateMsg] = useState('');

  useEffect(() => {
    getCategories();
    getAnnualSales(user);
  }, []);
  const updateCart = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(cartItems));
    setCartUpdateMsg("Cart updated successfully")
  };
  const handleChange = (id, event) => {
    const newQty = event.target.value;
    const newItems = cartItems.map(item => {
      if (item.id === id) {
        return { ...item, order_qty: newQty };
      }
      return item;
    });
    setCartItems(newItems);
  };
  async function getCategories() {
    const parent = await api.get("categories");
    const parentData = parent.data;
    setCategories(parentData);
  }
  async function getAnnualSales(user) {
    // console.log('localUser: ',localUser);
    if (user && user.user_id) {
      const totalAnnualSales = await api.get(
        `userorder/annual/${user.user_id}`
      );
      if (totalAnnualSales.data) {
        setAnnualpurchase(totalAnnualSales?.data?.annual_sales);
      }
    }
  }
  let discountTotal = 0;
  let subTotal = 0;
  let tax = 0;
  let grandTotal = 0;
  let grandTotalWithCardPayment = 0;
  let totalVolume = 0;
  let totalWeight = 0;
  let netExplosiveWeight = 0;
  const lastLocation = useLocation();
  // console.log("lastLocation", lastLocation);
  const loginUrl =
    user && user.isLoggedin
      ? `/checkout/shipping`
      : `/user/login?redirectTo=${lastLocation.pathname}`;
  // console.log("loginUrl", loginUrl);

  const listItems = cartItems.map((item) => {
    discountTotal += 0;
    subTotal += item?.price * item.order_qty;
    totalVolume += item?.volume * item.order_qty;
    totalWeight += item?.weight * item.order_qty;
    netExplosiveWeight += item?.explosive_weight * item.order_qty;
    return (
      <Table.Row key={item.id}>
        <Table.Cell width={1}>
          <Form.Input
            fluid
            value={item.order_qty}
            id={`qty_${item.id}`}
            pid={item.id}
            onChange={event => handleChange(item.id, event)}
          />
        </Table.Cell>
        <Table.Cell>{item.part_number}</Table.Cell>
        <Table.Cell>{item?.manufacturers?.name}</Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.pkg}</Table.Cell>
        <Table.Cell>{currencyFormat(item?.price)}</Table.Cell>
        <Table.Cell>{currencyFormat(item?.price * item.order_qty)}</Table.Cell>
      </Table.Row>
    );
  });
  tax = (subTotal * 0) / 100;
  grandTotal = subTotal + tax;
  grandTotalWithCardPayment = grandTotal + (grandTotal * 3) / 100;

  let navigate = useNavigate();
  const clearCartHandler = async () => {
    await localStorage.removeItem(LOCAL_STORAGE_KEY_CART);
    navigate("/shop");
  };
  const handleCheckout = async (event, data) => {
    /*event.preventDefault();
     */
    let cs = {
      total_price: subTotal,
      discount_price: discountTotal,
      discount_percentage: getDiscountTotal(),
      subtotal: subTotal,
      salestax: tax,
      grand_total: grandTotal - getDiscountTotal(),
      creditcard_discount:
        grandTotal -
        getDiscountTotal() +
        ((grandTotal - getDiscountTotal()) * 3) / 100,
      total_volume: totalVolume,
      total_weight: totalWeight,
      net_explosive_weight: netExplosiveWeight,
      status: "pending",
    };
    setCartSummery(cs);
    await localStorage.setItem(
      LOCAL_STORAGE_KEY_CART_SUMMERY,
      JSON.stringify(cs)
    );
    navigate(loginUrl);
  };
  function getDiscountTotal() {
    let annualUserDiscount = 0;
    if (subTotal + annualpurchase > 10000) {
      annualUserDiscount = 10;
      // console.log("annualUserDiscount", annualUserDiscount);
    }
    if (annualpurchase >= 100000) {
      annualUserDiscount = 20;
      // console.log("annualUserDiscount", annualUserDiscount);
    }
    // console.log("subTotal",subTotal);
    // console.log("annualUserDiscount",annualUserDiscount);
    // console.log((subTotal * annualUserDiscount) / 100);
    return (subTotal * annualUserDiscount) / 100;
  }
  return (
    <div>
      <Grid
        columns={2}
        divided
        style={{ margin: "1em 0em 0em", padding: "1em 0em" }}
      >
        <Grid.Row only="computer">
          <Grid.Column width={3} className="ui left">
            <CategoryFilter categories={categories} catid={0} />
          </Grid.Column>
          <Grid.Column width={13}>
            {cartUpdateMsg ? (
              <Message positive>
                <Message.Header>{cartUpdateMsg}</Message.Header>
              </Message>
            ) : (
              ""
            )}
            {cartItems.length > 0 ? (
              <>
                <h2>Your Cart</h2>
                <Form>
                  <Table compact celled unstackable style={{ margin: "5em 0em 0 em" }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Qty</Table.HeaderCell>
                        <Table.HeaderCell>Part Number</Table.HeaderCell>
                        <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Pkg</Table.HeaderCell>
                        <Table.HeaderCell>List Price</Table.HeaderCell>
                        <Table.HeaderCell>Subtotal</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>{listItems}</Table.Body>
                  </Table>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell colSpan="5">
                          <Button basic color="blue" onClick={updateCart}>
                            Update Cart
                          </Button>
                        </Table.Cell>
                        <Table.Cell colSpan="3">
                          <Table compact className="noborder">
                            <Table.Body>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Subtotal:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(subTotal)}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Volume Discount Savings
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {" "}
                                  - {currencyFormat(getDiscountTotal())}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Applicable Sales Tax:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(tax)}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Total:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(
                                    grandTotal - getDiscountTotal()
                                  )}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Our 3% cash discount will be removed for
                                  credit card or PayPal payments. Total will be:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(
                                    grandTotal -
                                      getDiscountTotal() +
                                      ((grandTotal - getDiscountTotal()) * 3) /
                                        100
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          Order weight & volume are estimated values.
                          <br />
                          We strive to provide you with the most accurate data,
                          but there is no guarantee.
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          <Button basic color="red" onClick={clearCartHandler}>
                            Cancel Order
                          </Button>{" "}
                          or{" "}
                          <Button color="blue" onClick={handleCheckout}>
                            {user && user.isLoggedin
                              ? "Begin Checkout"
                              : "Login to Begin Checkout"}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Form>
              </>
            ) : (
              <>
                <h2>Your Cart is empty.</h2>
                <p>
                  Go to <a href="/shop">Shop</a> and buy some products.
                </p>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile">
          <Grid.Column width={16}>
            {cartUpdateMsg ? (
              <Message positive>
                <Message.Header>{cartUpdateMsg}</Message.Header>
              </Message>
            ) : (
              ""
            )}
            {cartItems.length > 0 ? (
              <>
                <h2>Your Cart</h2>
                <Form>
                  <Table compact celled style={{ margin: "5em 0em 0 em" }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Qty</Table.HeaderCell>
                        <Table.HeaderCell>Part Number</Table.HeaderCell>
                        <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Pkg</Table.HeaderCell>
                        <Table.HeaderCell>List Price</Table.HeaderCell>
                        <Table.HeaderCell>Subtotal</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>{listItems}</Table.Body>
                  </Table>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell colSpan="5">
                          <Button basic color="blue" onClick={updateCart}>
                            Update Cart
                          </Button>
                          {/* <Table compact collapsing className="noborder">
                            <Table.Body>
                              <Table.Row>
                                <Table.Cell collapsing textAlign="left">
                                  Total Volume:
                                </Table.Cell>
                                <Table.Cell collapsing textAlign="left">
                                  {weightFormat(totalVolume)} m<sup>3</sup>
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell collapsing textAlign="left">
                                  Total Weight:
                                </Table.Cell>
                                <Table.Cell collapsing textAlign="left">
                                  {weightFormat(totalWeight)} kg
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell collapsing textAlign="left">
                                  Net Explosive Wt.:
                                </Table.Cell>
                                <Table.Cell collapsing textAlign="left">
                                  {weightFormat(netExplosiveWeight)} kg
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table> */}
                        </Table.Cell>
                        <Table.Cell colSpan="3">
                          <Table compact className="noborder">
                            <Table.Body>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Subtotal:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(subTotal)}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Volume Discount Savings
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {" "}
                                  - {currencyFormat(getDiscountTotal())}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Applicable Sales Tax:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(tax)}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Total:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(
                                    grandTotal - getDiscountTotal()
                                  )}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell textAlign="right">
                                  Our 3% cash discount will be removed for
                                  credit card or PayPal payments. Total will be:
                                </Table.Cell>
                                <Table.Cell textAlign="right">
                                  {currencyFormat(
                                    grandTotal -
                                      getDiscountTotal() +
                                      ((grandTotal - getDiscountTotal()) * 3) /
                                        100
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          Order weight & volume are estimated values.
                          <br />
                          We strive to provide you with the most accurate data,
                          but there is no guarantee.
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          <Button basic color="red" onClick={clearCartHandler}>
                            Cancel Order
                          </Button>{" "}
                          or{" "}
                          <Button color="blue" onClick={handleCheckout}>
                            {user && user.isLoggedin
                              ? "Begin Checkout"
                              : "Login to Begin Checkout"}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Form>
              </>
            ) : (
              <>
                <h2>Your Cart is empty.</h2>
              </>
            )}
            <p>
              Go to <a href="/shop">Shop</a> and buy some products.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default Cart;
