import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Segment,
  Header,
  Table,
  Grid,
  Image,
  Container,
  Button,
  Icon,Input
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { APIBASEURL, currencyFormat } from "../../config";
import api from "../../api";
import { useNavigate, useLocation } from "react-router";

const OrderEdit = () => {
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const [orderItemsList, setOrderItemsList] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [removedOrderItems, setRemovedOrderItems] = useState([]);
  const [checkouts, setCheckouts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [address, setAddress] = useState([]);
  const [pickup_types, setPickupTypes] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [salestax, setSalestax] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [grandtotal, setGrandtotal] = useState(0);
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  let i = 0;

  useEffect(() => {
    const getOrderDetails = async () => {
      axios
        .get(`${APIBASEURL}orders/${id}`)
        .then((response) => {
          const allCustomers = response.data;
          setOrders(allCustomers.order_details);
          setOrderItems(allCustomers.order_items);
          setCheckouts(allCustomers.checkouts);
          setCustomers(allCustomers.order_details.user);
          setAddress(allCustomers.checkouts.address);
          setPickupTypes(allCustomers.checkouts.pickup_types);
        })
        .catch((error) => console.log(`Error: ${error}`));
    };
    getOrderDetails();
    setDiscount(orders.discount_price);
    setSalestax(orders.salestax);
    setSubtotal(orders.subtotal);
    setGrandtotal(orders.grand_total);
    handleOrderItemList();
  }, []);

  const handleDiscount = (e)=>{
    let subtotal = orders.total_price-parseFloat(e.target.value);
    let grand_total = subtotal+parseFloat(orders.salestax);
    const updatedOrder = { ...orders, subtotal: subtotal, grand_total: grand_total, discount_price: e.target.value}
    setOrders(updatedOrder);
  };
  const handleSalestax = (e)=>{
    let grand_total = orders.subtotal+parseFloat(e.target.value);
    const updatedOrder = { ...orders, grand_total: grand_total, salestax: e.target.value}
    setOrders(updatedOrder);
  };
  useEffect(() => {
    updateSubtotal();
    handleOrderItemList()
  }, [orderItems]);

  const updateSubtotal = () => {
    let total_price = 0;
    let subtotal = 0;
    orderItems.map(item => {
      total_price = total_price + parseFloat(item.subtotal)
    });
    subtotal = total_price - parseFloat(orders.discount_price);
    let grand_total = subtotal + parseFloat(orders.salestax);
    const updatedOrder = { ...orders, total_price: total_price, subtotal: subtotal, grand_total: grand_total}
    setOrders(updatedOrder);
  }
  const handleQty = (e,item_id) => {
    const updatedData = orderItems.map(item => {
      if (item.id === item_id) {
        return { ...item, quantity: e.target.value, subtotal: e.target.value * item.price };
      }
      return item;
    });
    setOrderItems(updatedData);
  };
  const removeOrderItem = (e,item_id) => {
    setRemovedOrderItems([...removedOrderItems,item_id])
    setOrderItems(orderItems.filter(item => {
      if (item.id !== item_id) {
        return item;
      }
    }));
  };

  let navigate = useNavigate();
  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("order_details", JSON.stringify(orders));
    formData.append("order_items", JSON.stringify(orderItems));
    formData.append("removedOrderItems", JSON.stringify(removedOrderItems));
    try {
        console.log("formData",formData);
        const response = await api.post("orders-update", formData);
        console.log(response);
        if (response.status === 200 && response.data.status === true) {
          setSuccess("Product Updated Successfully");
          navigate("/admin/orders/"+orders.id);
        } else {
          setError("Invalid Input, Failed to Update Order");
        }
      } catch (error) {
        console.log("Error", error.message);
        setError("Invalid Input, Failed to Update Order");
      }
  };
  const handleCancel = () => {
    navigate("/admin/orders/"+orders.id);
  }
  const handleOrderItemList = () => {
    setOrderItemsList(orderItems.map((oi) => (
      <>
        <Table.Row key={i++}>
          <Table.Cell key={i++}><Input value={oi.quantity} onChange={(event)=>handleQty(event,oi.id)} className="discount-input"/></Table.Cell>
          <Table.Cell key={i++}>{oi.part_number}</Table.Cell>
          <Table.Cell key={i++}>{oi.manufacturer_name}</Table.Cell>
          <Table.Cell key={i++}>{oi.name}</Table.Cell>
          <Table.Cell key={i++}>{oi.pkg}</Table.Cell>
          <Table.Cell key={i++}>{currencyFormat(oi.price)}</Table.Cell>
          <Table.Cell key={i++}>{currencyFormat(oi.subtotal)}</Table.Cell>
          <Table.Cell key={i++}>{currencyFormat(oi.subtotal)}</Table.Cell>
          <Table.Cell key={i++}>
          <Button.Group basic size='small'><Button icon='close' onClick={(e)=>removeOrderItem(e,oi.id)}/></Button.Group>
          </Table.Cell>
        </Table.Row>
      </>
    )));
  }
  if (orders) {
    return (
      <>
        <Header as="h2" key={i++} textAlign="center">
          Update Order
        </Header>
        <Container>
          <Segment key={i++} id="report">
            <Grid divided="vertically" key={i++}>
              <Grid.Row columns={2} key={i++}>
                <Grid.Column key={i++}>
                  <Image src="/images/logo.png" height="120" key={i++} />
                </Grid.Column>
                <Grid.Column textAlign="right" key={i++}>
                  <h2 key={i++}>Order#{orders.id}</h2>
                  <p key={i++}>Date: {orders.order_date}</p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={3} key={i++}>
                <Grid.Column key={i++}>
                  <h4 key={i++}>Remit Payments To:</h4>
                  <p key={i++}>Munnerlyn Pyrotechnics</p>
                  <p key={i++}>Lexington SC 29072</p>
                </Grid.Column>

                <Grid.Column key={i++}>
                  <h4 key={i++}>Bill To:</h4>
                  <p key={i++}>{customers?.name}</p>
                  <p key={i++}>{address?.street}</p>
                  <p key={i++}>
                    {address?.city}, {address?.state} {address?.zip}
                  </p>
                </Grid.Column>

                <Grid.Column key={i++}>
                  <h4>{pickup_types.name}</h4>
                  <p>{pickup_types.pickup_by_name}</p>
                  <p>{pickup_types.event_name_Location}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Table key={i++}>
              <Table.Header key={i++}>
                <Table.Row key={i++}>
                  <Table.HeaderCell key={i++}>Qty</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Part Number</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Manufacturer</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Description</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Pkg</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>List Price</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Your Price</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>Line Total</Table.HeaderCell>
                  <Table.HeaderCell key={i++}>&nbsp;</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body key={i++}>
                {orderItemsList}
              </Table.Body>
              <Table.Footer key={i++}>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    List Price Subtotal
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++} colSpan={2}>
                    {currencyFormat(orders.total_price)}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    Discount
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++} colSpan={2}>
                    $ <Input value={orders.discount_price} onChange={handleDiscount} className="discount-input"/>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    Subtotal
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++} colSpan={2}>
                    {currencyFormat(parseFloat(orders.subtotal))}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={7} textAlign="right" key={i++}>
                    Applicable Sales Tax
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++} colSpan={2}>
                    $ <Input value={orders.salestax} onChange={handleSalestax} className="discount-input"/>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={6} textAlign="center" key={i++}>
                    Our 3% cash discount will be removed for Credit or PayPal
                    payments
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={1} textAlign="right" key={i++}>
                    Total
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++} colSpan={2}>
                    {currencyFormat(parseFloat(orders.grand_total))}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row key={i++}>
                  <Table.HeaderCell colSpan={6} textAlign="center" key={i++}>
                    
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan={2} textAlign="right" key={i++}>
                  <Button onClick={handleCancel}>Cancel</Button>
                  </Table.HeaderCell>
                  <Table.HeaderCell key={i++}>
                    <Button primary onClick={handleSave}>Save</Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <h3 key={i++}>Order Note</h3>
            <p key={i++}>{checkouts?.order_note}</p>
          </Segment>
        </Container>
      </>
    );
  }
  return null;
};
export default OrderEdit;
